import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConversationStore } from "@presentation/store/conversation-store";
import { Socket } from "socket.io-client";

export default function onImportantConversationList (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("importantConversation", (result) => {
    // handleDebug("importantConversation", result.data);
    const conversationStore = useConversationStore();
    conversationStore.data.importantConversations = result.data.conversation.items;
  });
}

export function isImage (type?: string) {
  if (type && type.includes("image")) return true;

  return false;
}

export function isVideo (type?: string) {
  if (type && type.includes("video")) return true;

  return false;
}

export function isAudio (type?: string) {
  if (type && type.includes("audio")) return true;

  return false;
}

export function isApplication (type?: string) {
  if (type && type.includes("application")) return true;

  return false;
}

export function isDoc (type?: string) {
  if (type && type.includes("doc")) return true;

  return false;
}

export function isZip (type?: string) {
  if (type && type.includes("zip")) return true;

  return false;
}

export function isPDF (type?: string) {
  if (type && type.includes("pdf")) return true;

  return false;
}

export function getFileUrl (file: File) {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return window.URL.createObjectURL(file);
}

export function calculateFileSize (fileSize: number) {
  const units = ["KB", "MB", "GB"];
  let size = fileSize;
  let unit = "";

  for (let index = 0; index < units.length; index++) {
    const element = units[index];

    if (size > 1024) {
      size = size / 1024;
      unit = element;
    }
  }

  return `${Math.round(size)} ${unit}`;

  // switch (type) {
  //   case "KB":
  //     return Math.round(size / 1024);
  //   case "MB":
  //     return Math.round(size / (1024 * 1024));
  //   case "GB":
  //     return Math.round(size / (1024 * 1024 * 1024));
  // }
}

export function getBase64 (img: Blob): Promise<string> {
  if (!img) return Promise.resolve("");

  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });
}

export function getBlobFromBase64 (base64Data?: string) {
  if (!base64Data) return {} as Blob; // No data found

  // Convert Base64 back to Blob
  const byteString = atob(base64Data.split(",")[1]); // Remove metadata
  const mimeType = base64Data.split(",")[0].split(":")[1].split(";")[0]; // Extract MIME type

  const arrayBuffer = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    arrayBuffer[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeType });
}

export async function getWaveform (file: Blob, numSamples = 63): Promise<number[]> {
  return new Promise((resolve, reject) => {
    const audioContext = new AudioContext();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      try {
        const audioBuffer = await audioContext.decodeAudioData(reader.result as ArrayBuffer);
        const rawData = audioBuffer.getChannelData(0); // Get data from the first audio channel

        // Down sample to desired number of samples
        const step = Math.floor(rawData.length / numSamples);
        const waveform = Array.from({ length: numSamples }, (_, i) => {
          return Math.floor(Math.abs(rawData[i * step] * 10)); // Normalize & scale
        });

        resolve(waveform);
      } catch (error) {
        resolve([]);
      }
    };

    reader.onerror = () => reject(reader.error);
  });
}

import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { GetUserSeenMessageQuery } from "@domain/interfaces/message-interface";

export default function getUserSeenMessageUseCase (body: GetUserSeenMessageQuery) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.getUserSeenMessage(body);
}

import { API_SYS_ERROR } from "@common/kernel/error-code";
import { ITheAdvanceMediaResponse, ITheAdvanceMediaUploadResponseData } from "@domain/interfaces/sticker-interface";
import { useAccountStore } from "@presentation/store/account-store";
import axios from "axios";

export class TheAdvanceMediaResponse implements ITheAdvanceMediaResponse {
  status: number;
  code: number;
  message: string;
  axiosError?: any;
  data: any;

  constructor (params?: { status: number; code: number; message: string; data: any; axiosError?: any }) {
    this.code = params?.code || API_SYS_ERROR.code;
    this.status = params?.status || API_SYS_ERROR.status;
    this.message = params?.message || API_SYS_ERROR.message;
    this.axiosError = params?.axiosError;
    this.data = params?.data;
  }
}

export default class TheAdvanceMediaService {
  host: string;
  headers: object;

  constructor () {
    this.host = gConfig.theAdvanceMedia.host;
    this.headers = {
      product: gConfig.theAdvanceMedia.product,
      token: gConfig.theAdvanceMedia.token,
    };
  }

  async upload (data: { path: string; file: File | Blob; fileName: string; duration?: number; waveform?: number[]; employeeId: string; convert?: string }) {
    try {
      const formData = new FormData();
      formData.append("file", data.file, data.fileName);

      if (data.duration) {
        formData.append("duration", data.duration.toString());
      }
      formData.append("filename", data.fileName);

      if (data.waveform) {
        formData.append("waveform", JSON.stringify(data.waveform));
      }

      if (data.convert) {
        formData.append("convert", data.convert);
      }

      const headers = { ...this.headers, authorization: "", employeeId: data.employeeId };
      const url = `${this.host}${data.path}`;
      const response = await axios.post(url, formData, { headers });

      return new TheAdvanceMediaResponse(response.data);
    } catch (error) {
      return new TheAdvanceMediaResponse({
        status: API_SYS_ERROR.status,
        code: API_SYS_ERROR.code,
        message: API_SYS_ERROR.message || "",
        axiosError: error,
        data: {} as ITheAdvanceMediaUploadResponseData,
      });
    }
  }

  async get ({ path, query }: { path: string; query: any }) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.get(url, {
        params: query,
        headers: {
          ...this.headers,
          timestamp: Date.now(),
          username: useAccountStore().loginUser.username,
        },
      });

      return new TheAdvanceMediaResponse(response.data);
    } catch (error) {
      return new TheAdvanceMediaResponse({
        status: API_SYS_ERROR.status,
        code: API_SYS_ERROR.code,
        message: API_SYS_ERROR.message || "",
        axiosError: error,
        data: {},
      });
    }
  }
}

import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { GetMessageCountQuery } from "@domain/interfaces/message-interface";

export default function getMessageCountUseCase (body: GetMessageCountQuery) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.getMessageCount(body);
}

import configDevelopment from "./config.development";
import configLocal from "./config.local";
import configProduction from "./config.production";

const environment = process.env.VUE_APP_ENV;
let defaultConfig = configLocal;

if (environment === "development") defaultConfig = configDevelopment;
else if (environment === "production") defaultConfig = configProduction;
console.log(appVersion);
globalThis.gConfig = defaultConfig;

export default defaultConfig;

import { GetStickerListQuery } from "@domain/interfaces/sticker-interface";

import repository from "@config/repository";
import StickerRepository from "@domain/repositories/theadvance-media/sticker-repository";

export default async function getStickerListUseCase (query: GetStickerListQuery) {
  const mediaRepository = repository.get(StickerRepository);

  return await mediaRepository.getStickerList(query);
}

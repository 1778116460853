/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { ICreateFolderBody, ICreateFolderResponse, IGetFolderListQuery, IGetFolderListResponse, IUpdateFolderBody } from "@domain/interfaces/folder-interface";

export default abstract class FolderRepository {
  getList (query: IGetFolderListQuery): Promise<IGetFolderListResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  createFolder (body: ICreateFolderBody): Promise<ICreateFolderResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  removeFolder (id: string): Promise<ICreateFolderResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  updateFolder (body: IUpdateFolderBody): Promise<ICreateFolderResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}

import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { UpdateConversationDetailsBody } from "@domain/interfaces/conversation-details-interface";

export default async function updateConversationDetailsUseCase (data: UpdateConversationDetailsBody) {
  const conversationDetailsRepository = repository.get<ConversationRepository>(ConversationRepository);

  return await conversationDetailsRepository.updateConversationDetails(data);
}

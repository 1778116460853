/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { SpeechToTextBody, SpeechToTextResponse } from "@domain/interfaces/attachment-interface";

export default abstract class AttachmentRepository {
  speechToText (data: SpeechToTextBody): Promise<SpeechToTextResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}

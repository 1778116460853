import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConversationStore } from "@presentation/store/conversation-store";
import { useMessageStore } from "@presentation/store/message-store";
import { Socket } from "socket.io-client";
import { handleDebug } from "../helper";

export default function onUserSeenMessage (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("userSeen", (response) => {
    handleDebug("userSeen", response.data);
    const conversationStore = useConversationStore();

    conversationStore.updateLastMessageStatus(response.data.message);

    if (response.data.message.conversationId === conversationStore.selectedConversation.id) {
      const messageStore = useMessageStore();

      for (let index = 0; index < messageStore.data.length; index++) {
        if (!messageStore.data[index].seen || new Date(messageStore.data[index].createdAt).getTime() <= new Date(response.data.message.createdAt).getTime()) {
          messageStore.data[index].seen = true;
        }
      }
    }
  });
}

import Conversation from "@domain/entities/conversation-entity";
import { getMentionsUser } from "@domain/helpers/message-helper";

export default function getMessageMentionUserUseCase (content: string, selectedConversation: Conversation) {
  if (!content) return [];

  return getMentionsUser(content, {
    nameOnly: true,
    unique: true,
  })
    .filter((m) => selectedConversation.members?.includes(m))
    .map((username) => ({
      text: `@${username}`,
      username,
      label: selectedConversation.membersInfo.find((item) => item.username === username)?.name || username,
    }));
}

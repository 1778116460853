import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConversationStore } from "@presentation/store/conversation-store";
import { useFolderStore } from "@presentation/store/folder-store";
import { useMessageStore } from "@presentation/store/message-store";
import { useStickerStore } from "@presentation/store/sticker-store";
import { useUserStore } from "@presentation/store/user-store";
import { Socket } from "socket.io-client";

export default async function onConnected (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("connect", () => {
    useConversationStore().getConversationList();
    useMessageStore().getUnreadMessageCount();
    useUserStore().getUserList({ page: 1 });
    useFolderStore().getFolderList({ page: 1 });
    useStickerStore().getStickerList();

    console.log("Kết nối thành công!");
  });
}

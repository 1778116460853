import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { UpdatePinConversationBody } from "@domain/interfaces/conversation-interface";

export default async function updatePinConversationUseCase (data: UpdatePinConversationBody) {
  const conversationDetailsRepository = repository.get<ConversationRepository>(ConversationRepository);

  return await conversationDetailsRepository.updatePinConversation(data);
}

import repository from "@config/repository";
import FolderRepository from "@domain/repositories/folder-repository";

import { IUpdateFolderBody } from "@domain/interfaces/folder-interface";

export default function updateFolderServerUseCase (body: IUpdateFolderBody) {
  const folderRepository = repository.get(FolderRepository);

  return folderRepository.updateFolder(body);
}

import UploadMediaRepository from "@domain/repositories/theadvance-media/media-repository";
import TheAdvanceMediaService, { TheAdvanceMediaResponse } from "@infrastructure/network/theadvance-media";

import { getWaveform } from "@common/file";
import { GetStickerListQuery, GetStickerListResponse } from "@domain/interfaces/sticker-interface";
import { useAccountStore } from "@presentation/store/account-store";
import { injectable } from "inversify";

@injectable()
export default class TheAdvanceMediaUploadRepository implements UploadMediaRepository {
  private api = new TheAdvanceMediaService();
  async uploadMedia (file: File): Promise<TheAdvanceMediaResponse> {
    return await this.api.upload({
      path: "/chat/upload",
      file,
      fileName: file.name,
      employeeId: useAccountStore().loginUser.username,
    });
  }

  async uploadRecord (blob: Blob, duration = 0): Promise<TheAdvanceMediaResponse> {
    const waveform = await getWaveform(blob);

    return await this.api.upload({
      path: "/chat/upload",
      file: blob,
      fileName: `${Date.now()}_record.m4a`,
      duration,
      waveform,
      employeeId: useAccountStore().loginUser.username,
      convert: "aac",
    });
  }

  async uploadAvatar (file: File): Promise<TheAdvanceMediaResponse> {
    return await this.api.upload({
      path: "/user/avatar",
      file,
      fileName: file.name,
      employeeId: useAccountStore().loginUser.username,
    });
  }

  async getStickerList (query: GetStickerListQuery): Promise<GetStickerListResponse> {
    return await this.api.get({
      path: "/user/avatar",
      query,
    });
  }
}

<template>
  <div class="animated-button">
    <slot />
  </div>
</template>
<style lang="css">
.animated-button {
  @apply p-2;
  transition: display 0.5s;
}
.animated-button:active {
  transform: scale(0.9);
  transition: transform 0.1s ease-in-out;
  background-color: rgba(112, 117, 121, 0.1);
  @apply rounded-full;
}
</style>

export const LOGIN_LOGO = "/logo/login.svg";

export const INFO_ICON = "/icon/info.svg";

export const EDIT_ICON = "/icon/edit.png";

export const DELETE_ICON = "/icon/delete.svg";

export const CREATE_ICON = "/icon/add.svg";

export const QUESTION_ICON = "/icon/question.svg";

export const PAPERCLIP_ICON = "/icon/paperclip.png";

export const SMILE_ICON = "/icon/smile-icon.png";

export const CHAT_TEARDROP_DOTS_ICON = "/icon/chat-teardrop-dots.png";

export const SETTING_ICON = "/icon/setting.png";

export const ACCOUNT_ICON = "/icon/account-ic.png";

export const SEARCH_ICON = "/icon/search-icon.png";

export const SEARCH_ICON_GRAY_50 = "/icon/search-gray-50.png";

export const PLUS_ICON = "/icon/plus-ic.png";

export const SMILEY_STICKER_ICON = "/icon/smiley-sticker.png";

export const CLOSE_ICON = "/icon/close.png";

export const FILE_DOC = "/icon/file-doc.png";

export const FILE_PDF = "/icon/file-pdf.png";

export const FILE_ZIP = "/icon/file-zip.png";

export const USER_PLUS = "/icon/user-plus.png";

export const USER_GROUP = "/icon/user-group.png";

export const REMOVE_MEMBER = "/icon/remove-member.png";

export const SEND_MESSAGE_ICON = "/icon/send-message.png";

export const ADD_FOLDER_ICON = "/icon/add-folder.png";

export const MUTE_ICON = "/icon/mute.png";

export const UNMUTE_ICON = "/icon/unmute.png";

export const PIN_ICON = "/icon/pin.png";

export const UNPIN_ICON = "/icon/unpin.png";

export const IS_PIN_ICON = "/icon/is-pin-message.png";

export const REPLY_ICON = "/icon/reply.png";

export const CHECK_CIRCLE_ICON = "/icon/check-circle.png";

export const CHECK_16_ICON = "/icon/checks-16.png";

export const COPY_ICON = "/icon/copy.png";

export const FORWARD_ICON = "/icon/forward.png";

export const ARROW_DOWN_ICON = "/icon/arrow-down.png";

export const ARROW_UP_ICON = "/icon/arrow-up.png";

export const ARROW_LEFT_ICON = "/icon/arrow-left.png";

export const ARROW_RIGHT_ICON = "/icon/arrow-right.png";

export const LOGOUT_ICON = "/icon/logout-ic.png";

export const IMAGE_ICON = "/icon/image-ic.png";

export const BOOKMARK_ICON = "/icon/bookmark-ic.png";

export const HEART_PRIMARY_ICON = "/icon/heart-primary.png";

export const ALL_CHAT_ICON = "/icon/all-chat.png";

export const FOLDER_ICON = "/icon/folder.png";

export const RIGHT_BAR_ICON = "/icon/open-right-bar.png";

export const RIGHT_BAR_PRIMARY_ICON = "/icon/open-right-bar-primary.png";

export const ADD_USER_ICON = "/icon/add-user.png";

export const USER_ICON = "/icon/user.png";

export const CHECKED_ICON = "/icon/check.png";

export const CHECKED_CIRCLE_ICON = "/icon/checked-circle.png";

export const UNCHECK_CIRCLE_ICON = "/icon/uncheck-circle.png";

export const EDITED_ICON = "/icon/edited.png";

export const PIN_MESSAGE_ICON = "/icon/pin-message-list.png";

export const BOOKMARK_FILLED_ICON = "/icon/bookmark-filled.png";

export const CAMERA_ICON = "/icon/camera.png";

export const MICROPHONE_ICON = "/icon/microphone.png";

export const SEND_RECORD_ICON = "/icon/send-record.png";

export const REMOVE_RECORD_ICON = "/icon/remove-record.png";

export const RECORD_ICON = "/icon/record.png";

export const PLAY_RECORD_CIRCLE_ICON = "/icon/circle-play-record.png";

export const PAUSE_RECORD_CIRCLE_ICON = "/icon/circle-pause-record.png";

export const PREVIOUS_TRACK_ICON = "/icon/skip-back.png";

export const PAUSE_ICON = "/icon/pause.png";

export const PLAY_ICON = "/icon/play.png";

export const NEXT_TRACK_ICON = "/icon/skip-forward.png";

export const CLOSE_CIRCLE_ICON = "/icon/close-circle.png";

export const RECORD_SPEAKER_ICON = "/icon/record-speaker.png";

export const RECORD_SPEAKER_MUTE_ICON = "/icon/record-speaker-mute.png";

export const CIRCLE_PLAY_VIDEO_ICON = "/icon/circle-play-video.png";

export const REMOVE_FILE_CIRCLE_ICON = "/icon/remove-file-circle.png";

export const CALENDAR_PLUS_ICON = "/icon/calendar-plus.png";

export const CONVERSATION_MUTE_ICON = "/icon/conversation-mute.png";

// new

export const REMOVE_FOLDER_ICON = "/icon/RemoveFolder.png";

export const USER_CIRCLE_GEAR_ICON = "/icon/UserCircleGear.png";

export const TEXT_BOLDER_ICON = "/icon/TextBolder.png";

export const TEXT_ITALIC_ICON = "/icon/TextItalic.png";

export const TEXT_UNDERLINE_ICON = "/icon/TextUnderline.png";

export const TEXT_STRIKETHROUGH_ICON = "/icon/TextStrikethrough.png";

export const FOLDER_PLUS_64_ICON = "/icon/FolderPlus64.png";

export const FOLDER_OPEN_64_ICON = "/icon/FolderOpen64.png";

export const DOTS_THREE_VERTICAL_ICON = "/icon/DotsThreeVertical.png";

export const LIST_ICON = "/icon/List.png";

export const TRASH_ICON = "/icon/Trash1.png";

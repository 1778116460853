import repository from "@config/repository";

import FolderRepository from "@domain/repositories/folder-repository";

import { IGetFolderListQuery } from "@domain/interfaces/folder-interface";

export default function getFolderListUseCase (query: IGetFolderListQuery) {
  const folderRepository = repository.get(FolderRepository);

  return folderRepository.getList(query);
}

import repository from "@config/repository";

import FolderRepository from "@domain/repositories/folder-repository";

import { ICreateFolderBody } from "@domain/interfaces/folder-interface";

export default function createFolderServerUseCase (body: ICreateFolderBody) {
  const folderRepository = repository.get(FolderRepository);

  return folderRepository.createFolder(body);
}

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute top-5 z-50 w-full" }
const _hoisted_2 = { class: "flex items-center justify-center" }

import { onReceivedMessage } from "@infrastructure/firebase/cloud-messaging";
import { useAlertStore } from "@presentation/store/alert-store";
import { useConfigStore } from "@presentation/store/config-store";
import { message as antMessage } from "ant-design-vue";
import { storeToRefs } from "pinia";
import { watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const alertStore = useAlertStore();
const configStore = useConfigStore();

const { message, type } = storeToRefs(alertStore);
watch(
  [message, type],
  () => {
    if (message.value === "") return;

    if (type.value === "success") {
      return antMessage.success(message.value, undefined, () => {
        alertStore.clear();
      });
    }

    if (type.value === "error") {
      return antMessage.error(message.value, undefined, () => {
        alertStore.clear();
      });
    }

    if (type.value === "warning") {
      return antMessage.warning(message.value, undefined, () => {
        alertStore.clear();
      });
    }
  },
  { deep: true, immediate: true }
);

if (!("Notification" in window)) {
  // Check if the browser supports notifications
  alert("This browser does not support desktop notification");
} else if (Notification.permission === "granted") {
  // Check whether notification permissions have already been granted;
  // if so, create a notification
  onReceivedMessage();
  // …
} else if (Notification.permission !== "denied") {
  // We need to ask the user for permission
  Notification.requestPermission().then((permission) => {
    // If the user accepts, let's create a notification
    if (permission === "granted") {
      // …
    }
  });
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    theme: _unref(configStore).theme
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_unref(configStore).newVersion)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "bg-white p-2 rounded-xl cursor-pointer hover:underline header-5",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(configStore).reload && _unref(configStore).reload(...args)))
              }, " Có phiên bản mới, vui lòng click để để cập nhật! "))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }, 8, ["theme"]))
}
}

})
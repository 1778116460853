/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { GetStickerListQuery, GetStickerListResponse } from "@domain/interfaces/sticker-interface";

export default abstract class StickerRepository {
  getStickerList (query: GetStickerListQuery): Promise<GetStickerListResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}

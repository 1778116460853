import repository from "@config/repository";
import AttachmentRepository from "@domain/repositories/attachment-repository";

import { SpeechToTextBody } from "@domain/interfaces/attachment-interface";

export default function speechToTextUseCase (body: SpeechToTextBody) {
  const conversationRepository = repository.get(AttachmentRepository);

  return conversationRepository.speechToText(body);
}

import { scrollToMessage } from "@domain/helpers/message-helper";
import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConversationStore } from "@presentation/store/conversation-store";
import { useFolderStore } from "@presentation/store/folder-store";
import { useMessageStore } from "@presentation/store/message-store";
import { Socket } from "socket.io-client";

export default function onNewMessage (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("newMessage", (response) => {
    // handleDebug("newMessage", response.data);
    const messageStore = useMessageStore();
    const conversationStore = useConversationStore();
    const folderStore = useFolderStore();

    conversationStore.updateLocalConversationLastMessage(response.data.conversation, response.data.message);
    const folder = response.data.folder?.find((item) => item.conversations.includes(response.data.conversation.id));

    if (folder && folder.unreadCount >= 0) {
      folderStore.updateFolderUnreadCount({ conversationId: response.data.message.conversationId, action: "update", updateCount: folder.unreadCount });
    }

    // if new message is in selected conversation
    if (response.data.message.conversationId === conversationStore.selectedConversation.id) {
      // if selected conversation is wheel up
      if (messageStore.wheel.up) {
        if (folder) {
          folderStore.updateFolderUnreadCount({ conversationId: response.data.message.conversationId, action: "update", updateCount: folder.unreadCount });
        }
        conversationStore.updateLocalLastSeenMessage({
          conversationId: conversationStore.selectedConversation.id,
          conversationUnreadCount: conversationStore.selectedConversation.unreadCount + 1,
          updateCount: conversationStore.selectedConversation.unreadCount + 1,
          action: "update",
          updateTotalUnrealCount: messageStore.unreadCount + 1,
        });

        return;
      }
      messageStore.addLocalSelectedConversationMessage(response.data.message);
      scrollToMessage(response.data.message.id);
      conversationStore.updateLocalLastSeenMessage({
        conversationId: conversationStore.selectedConversation.id,
        conversationUnreadCount: conversationStore.selectedConversation.unreadCount,
        updateCount: 0,
        action: "update",
        updateTotalUnrealCount: messageStore.unreadCount,
        lastSeenMessageId: conversationStore.selectedConversation.lastMessageInfo.id,
        lastSeenAt: conversationStore.selectedConversation.lastMessageInfo.createdAt,
      });
      conversationStore.updateLastSeenMessage({
        lastSeenAt: conversationStore.selectedConversation.lastMessageInfo.createdAt,
        lastSeenMessageId: conversationStore.selectedConversation.lastMessageInfo.id,
        conversationId: conversationStore.selectedConversation.id,
        readMessageCount: conversationStore.selectedConversation.unreadCount,
        readAll: true,
      });

      return;
    }
    // if new message is NOT in selected conversation
    messageStore.updateLocalTotalUnreadCount(messageStore.unreadCount + 1);
    // conversationStore.addLocalUnreadCount({
    //   conversation: response.data.conversation,
    //   updateCount: 1,
    //   action: "add",
    // });
  });
}

import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConversationStore } from "@presentation/store/conversation-store";
import { useMessageStore } from "@presentation/store/message-store";
import { Socket } from "socket.io-client";
import { handleDebug } from "../helper";

export default function onEditMessage (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("editMessage", (result) => {
    handleDebug("editMessage", result.data);
    const conversationStore = useConversationStore();
    const messageStore = useMessageStore();

    if (conversationStore.selectedConversation.id === result.data.message.conversationId) {
      messageStore.updateLocalMessage(result.data.message);
    }

    const conversation = conversationStore.conversationList.find((item) => item.id === result.data.message.conversationId);

    if (conversation && conversation.lastMessageInfo.id === result.data.message.id) {
      conversationStore.updateLocalConversationLastMessage(conversation, result.data.message);
    }
  });
}

import repository from "@config/repository";
import UserRepository from "@domain/repositories/user-repository";

import { IGetUserListQuery } from "@domain/interfaces/user-interface";

export default function getUserListUseCase (query: IGetUserListQuery) {
  const accountRepository = repository.get<UserRepository>(UserRepository);

  return accountRepository.getList(query);
}

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { TEXT_ITALIC_ICON } from "@common/icon-path";

export default /*@__PURE__*/_defineComponent({
  __name: 'TextItalicIcon',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", { src: _unref(TEXT_ITALIC_ICON) }, null, 8, _hoisted_1))
}
}

})
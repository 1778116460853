import Conversation from "@domain/entities/conversation-entity";

import addConversationMemberUseCase from "@domain/use-cases/conversation/add-members";
import createConversationUseCase from "@domain/use-cases/conversation/create-conversation";
import getConversationDetailUseCase from "@domain/use-cases/conversation/get-conversation-detail";
import getConversationListUseCase from "@domain/use-cases/conversation/get-conversation-list";
import getConversationMemberUseCase from "@domain/use-cases/conversation/get-conversation-member";
import getConversationFileListUseCase from "@domain/use-cases/conversation/get-file-list";
import getConversationMediaListUseCase from "@domain/use-cases/conversation/get-media-list";
import getPinConversationListUseCase from "@domain/use-cases/conversation/get-pin-conversation-list";
import getSaveMessageConversationUseCase from "@domain/use-cases/conversation/get-save-message-conversation";
import leaveConversationUseCase from "@domain/use-cases/conversation/leave-conversation";
import removeConversationUseCase from "@domain/use-cases/conversation/remove-conversation";
import removeMemberUseCase from "@domain/use-cases/conversation/remove-member";
import updateConversationNameUseCase from "@domain/use-cases/conversation/update-conversation-name";
import updateConversationDetailsUseCase from "@domain/use-cases/conversation/update-details";
import updatePinConversationUseCase from "@domain/use-cases/conversation/update-pin-conversation";
import uploadMediaUseCase from "@domain/use-cases/media/upload-media";
import searchMessageWithinConversationUseCase from "@domain/use-cases/message/search-conversation-message";

import { DEFAULT_LIMIT } from "@common/constant";
import { ConversationCategoryEnum } from "@domain/constant/conversation-constant";
import { MESSAGE_INPUT } from "@domain/constant/message-constant";
import { scrollToEndConversation } from "@domain/helpers/message-helper";
import { IConversationDetails, UpdateConversationDetailsBody } from "@domain/interfaces/conversation-details-interface";
import {
  ConversationMedia,
  CreateConversationBody,
  GetConversationDetailResponse,
  GetUserConversationQuery,
  IConversationMember,
  LinkInfo,
} from "@domain/interfaces/conversation-interface";
import { IMessage, SearchMessageItem, SearchMessageWithinConversationQuery } from "@domain/interfaces/message-interface";
import { IUser } from "@domain/interfaces/user-interface";
import getConversationLinkListUseCase from "@domain/use-cases/conversation/get-link-list";
import getLinkMetadataUseCase from "@domain/use-cases/conversation/get-link-metadata";
import { SelectProps } from "ant-design-vue";
import { defineStore } from "pinia";
import { useAccountStore } from "./account-store";
import { useAlertStore } from "./alert-store";
import { useAppStateStore } from "./app-store";
import { useAudioStateStore } from "./audio-store";
import { useChatDialogStateStore } from "./chat-dialog-store";
import { useFolderStore } from "./folder-store";
import { useLocalStorageStore } from "./local-storage-store";
import { useMessageStore } from "./message-store";
import { useSearchStore } from "./search-store";

export const useConversationStore = defineStore("conversation", {
  state: () => ({
    data: {
      pinConversations: [] as Conversation[],
      importantConversations: [] as Conversation[],
      conversations: {
        items: [] as Conversation[],
        total: 0,
        limit: 10,
        fetching: false,
        isMore: true,
        moreFetching: false,
      },
      folder: {
        activeId: "",
        conversationList: [] as Conversation[],
        pinList: [] as Conversation[],
        fetching: false,
        isMore: true,
        moreFetching: false,
      },
    },
    activeConversationMedia: {
      items: [] as ConversationMedia[],
      searchItems: [] as ConversationMedia[],
      isCall: false,
      isMore: false,
      fetching: false,
    },
    activeConversationFiles: {
      items: [] as ConversationMedia[],
      searchItems: [] as ConversationMedia[],
      isCall: false,
      fetching: false,
      isMore: false,
    },
    activeConversationMember: {
      isCall: false,
      items: [] as IConversationMember[],
      isMore: false,
      fetching: false,
      page: 0,
      searchItems: [] as IConversationMember[],
    },
    activeConversationLink: {
      items: [] as LinkInfo[],
      searchItems: [] as LinkInfo[],
      isCall: false,
      fetching: false,
      isMore: false,
    },
    searchQuery: <GetUserConversationQuery>{},
    selectedConversation: {} as Conversation,
    serverConversationDetails: {} as IConversationDetails,
    alerts: [] as string[],
    fetchConversationId: "",
  }),
  getters: {
    conversationList: (state) => {
      if (state.data.folder.activeId) {
        return state.data.folder.pinList
          .map((item) => ({
            ...item,
            category: ConversationCategoryEnum.pin,
            conversationDetails: {
              ...item.conversationDetails,
              isPin: true,
            },
          }))
          .concat(
            state.data.folder.conversationList.map((item: Conversation) => ({
              ...item,
              category: ConversationCategoryEnum.normal,
              conversationDetails: {
                ...item.conversationDetails,
                isPin: false,
              },
            }))
          );
      }

      const array = state.data.importantConversations
        ?.map((item) => ({ ...item, category: ConversationCategoryEnum.important }))
        ?.concat(
          state.data.pinConversations.map((item: Conversation) => ({
            ...item,
            category: ConversationCategoryEnum.pin,
            conversationDetails: {
              ...item.conversationDetails,
              isPin: true,
            },
          })),
          state.data.conversations.items.map((item: Conversation) => ({
            ...item,
            category: ConversationCategoryEnum.normal,
          }))
        );

      return array;
    },
    selectedConversationMentionList: (state) => {
      if (!state.selectedConversation.id || !state.selectedConversation.isGroup) return [];

      return state.selectedConversation.membersInfo
        .filter((item) => item.username !== useAccountStore().loginUser.username)
        .map((item) => ({
          label: item.name,
          value: item.username,
          avatar: item.avatar,
        }));
    },
    selectedConversationMemberOptions: (state) => {
      if (!state.selectedConversation.id || !state.selectedConversation.isGroup) return [];

      return state.selectedConversation.membersInfo.map((item) => ({
        label: item.name,
        value: item.username,
        avatar: item.avatar,
      })) as SelectProps["options"];
    },
    getActiveConversationMembers: (state) => {
      return (searchText: string) => {
        if (searchText) {
          return state.activeConversationMember.searchItems;
        }

        return state.activeConversationMember.items || [];
      };
    },
    getActiveConversationMedia: (state) => {
      return (searchText: string) => {
        if (searchText) {
          return state.activeConversationMedia.searchItems;
        }

        return state.activeConversationMedia.items || [];
      };
    },
    getActiveConversationFile: (state) => {
      return (searchText: string) => {
        if (searchText) {
          return state.activeConversationFiles.searchItems;
        }

        return state.activeConversationFiles.items || [];
      };
    },
    getActiveConversationLink: (state) => {
      return (searchText: string) => {
        if (searchText) {
          return state.activeConversationLink.searchItems;
        }

        return state.activeConversationLink.items || [];
      };
    },
  },
  actions: {
    async setActiveFolderId (folderId: string) {
      const searchSearch = useSearchStore();
      searchSearch.hideSearch();
      this.data.folder.activeId = folderId;

      if (folderId) {
        this.getFolderConversation({ folderId });
      }
    },

    async getFolderConversation (query: GetUserConversationQuery) {
      if (!query.folderId) return;

      if (query.updatedAt) {
        this.data.folder.moreFetching = true;
      } else {
        this.data.folder.isMore = true;
        this.data.folder.fetching = true;
      }

      const result = await getConversationListUseCase({ folderId: query.folderId });
      const pinResult = await getPinConversationListUseCase({ folderId: query.folderId });

      if (query.updatedAt) {
        this.data.folder.conversationList = this.data.folder.conversationList.concat(result.data.items);

        this.data.folder.moreFetching = false;
        this.data.folder.isMore = result.data.items.length > 0;
      } else {
        this.data.folder.conversationList = result.data.items;
        this.data.folder.pinList = pinResult.data.items;

        this.data.folder.fetching = false;
        this.data.folder.isMore = result.data.items.length === DEFAULT_LIMIT;
      }
    },

    async getConversationList () {
      this.data.conversations.fetching = true;
      this.data.conversations.items = [];
      this.data.pinConversations = [];

      const pinListResult = await getPinConversationListUseCase({ folderId: "" });
      const result = await getConversationListUseCase({});

      if (result.code !== 1) {
        useAlertStore().error(result.message);
        this.data.conversations.fetching = false;

        return;
      }

      this.data.pinConversations = pinListResult.data.items;
      this.data.conversations.items = result.data.items;
      this.data.conversations.total = result.data.total;
      this.data.conversations.limit = result.data.limit;
      this.data.conversations.fetching = false;
      this.data.conversations.moreFetching = false;
      this.data.conversations.isMore = result.data.items.length === result.data.limit;
    },
    async getMoreConversation () {
      if (this.data.conversations.isMore) {
        this.data.conversations.moreFetching = true;
        const result = await getConversationListUseCase({
          updatedAt: this.data.conversations.items[this.data.conversations.items.length - 1].updatedAt,
        });

        if (result.code !== 1) {
          useAlertStore().error(result.message);
          this.data.conversations.moreFetching = false;

          return;
        }

        this.data.conversations.moreFetching = false;
        this.data.conversations.isMore = result.data.items.length === result.data.limit;
        this.data.conversations.items = this.data.conversations.items.concat(result.data.items);
      }
    },
    async getConversationDetail (query: { id?: string; username?: string; message?: { id: string; createdAt: Date } }, force = false) {
      let result: GetConversationDetailResponse = {
        data: {} as Conversation,
        status: 0,
        code: 0,
        message: "",
      };

      useLocalStorageStore().updateDraftConversation({
        conversationId: this.selectedConversation.id,
        content: useChatDialogStateStore().getInputContent(MESSAGE_INPUT.main.input),
      });

      if (force) {
        result = await getConversationDetailUseCase(query);
      } else if (query.id && this.selectedConversation?.id && query.id === this.selectedConversation.id && !query.message?.createdAt) {
        return;
      } else if (query.username && !this.selectedConversation.members.includes(query.username)) {
        return;
      } else {
        result = await getConversationDetailUseCase(query);
      }

      if (result.code !== 1) {
        useAlertStore().error(result.message);

        return;
      }
      this.serverConversationDetails = structuredClone(result.data.conversationDetails);
      this.setSelectConversation(result.data, query.message);

      return result.data;
    },
    async createConversation (value: CreateConversationBody, avatarFile?: File) {
      let avatar = "";

      if (avatarFile) {
        const uploadResult = await uploadMediaUseCase(avatarFile);

        if (uploadResult.code === 1 && uploadResult.data.link) {
          avatar = uploadResult.data.link;
        }
      }
      const result = await createConversationUseCase({
        members: [useAccountStore().loginUser.username].concat(value.members),
        isGroup: value.isGroup,
        name: value.name,
        avatar,
      });

      if (result.code !== 1) {
        return useAlertStore().error(result.message);
      }

      this.data.conversations.items.unshift(result.data.conversation);
      this.setSelectConversation(result.data.conversation);
    },
    async getSaveMessageConversation () {
      const result = await getSaveMessageConversationUseCase();

      if (result.code === 1) {
        this.setSelectConversation(result.data.conversation);
      }
    },
    async getConversationMember (search?: string) {
      if (search) {
        this.activeConversationMember.searchItems = [];
        const result = await getConversationMemberUseCase({
          conversationId: this.selectedConversation.id,
          page: 1,
          search,
        });
        this.activeConversationMember.searchItems = result.data.items;

        return;
      }

      if (this.activeConversationMember.isCall) {
        return;
      }
      this.activeConversationMember.fetching = true;
      this.activeConversationMember.page = 1;

      const result = await getConversationMemberUseCase({
        conversationId: this.selectedConversation.id,
        page: this.activeConversationMember.page,
      });

      this.activeConversationMember.fetching = false;

      if (result.code !== 1) {
        // handleDebug("getConversationMember", { result, selectedConversation: this.selectedConversation });

        return;
      }

      this.activeConversationMember.items = result.data.items;
      this.activeConversationMember.isCall = true;
      this.activeConversationMember.isMore = result.data.items.length === result.data.limit;
    },
    async getMoreConversationMember () {
      this.activeConversationMember.fetching = true;
      this.activeConversationMember.page = this.activeConversationMember.page + 1;

      const result = await getConversationMemberUseCase({
        conversationId: this.selectedConversation.id,
        page: this.activeConversationMember.page,
      });

      this.activeConversationMember.fetching = false;

      if (result.code !== 1) {
        return;
      }

      this.activeConversationMember.items = this.activeConversationMember.items.concat(result.data.items);
      this.activeConversationMember.isMore = result.data.items.length === result.data.limit;
    },
    async getConversationMedia (search?: string) {
      if (search) {
        this.activeConversationMedia.searchItems = [];
        const result = await getConversationMediaListUseCase({
          conversationId: this.selectedConversation.id,
          search,
        });
        this.activeConversationMedia.searchItems = result.data.items;

        return;
      }

      if (this.activeConversationMedia.isCall) {
        return;
      }

      const mediaResult = await getConversationMediaListUseCase({ conversationId: this.selectedConversation.id });

      if (mediaResult.code === 1) {
        this.activeConversationMedia.items = this.activeConversationMedia.items = mediaResult.data.items;
        this.activeConversationMedia.isCall = true;
        this.activeConversationMedia.isMore = mediaResult.data.items.length === mediaResult.data.limit;
      }
    },
    async getMoreConversationMedia () {
      this.activeConversationMedia.fetching = true;

      const result = await getConversationMediaListUseCase({
        conversationId: this.selectedConversation.id,
        createdAtBefore:
          this.activeConversationMedia.items.length > 0
            ? this.activeConversationMedia.items[this.activeConversationMedia.items.length - 1].createdAt
            : undefined,
      });

      this.activeConversationMedia.fetching = false;

      if (result.code !== 1) {
        return;
      }
      this.activeConversationMedia.items = this.activeConversationMedia.items.concat(result.data.items);
      this.activeConversationMedia.isMore = result.data.items.length === result.data.limit;
    },
    async getConversationFile (search?: string) {
      if (search) {
        this.activeConversationFiles.searchItems = [];
        const result = await getConversationFileListUseCase({
          conversationId: this.selectedConversation.id,
          search,
        });
        this.activeConversationFiles.searchItems = result.data.items;

        return;
      }

      if (this.activeConversationFiles.isCall) {
        return;
      }

      const fileResult = await getConversationFileListUseCase({
        conversationId: this.selectedConversation.id,
        createdAtAfter: this.activeConversationFiles.items.length > 0 ? this.activeConversationFiles.items[0].createdAt : undefined,
      });

      if (fileResult.code === 1) {
        this.activeConversationFiles.items = fileResult.data.items;
        this.activeConversationFiles.isCall = true;
        this.activeConversationFiles.isMore = fileResult.data.items.length === fileResult.data.limit;
      }
    },
    async getMoreConversationFile () {
      this.activeConversationFiles.fetching = true;

      const result = await getConversationFileListUseCase({
        conversationId: this.selectedConversation.id,
        createdAtBefore:
          this.activeConversationFiles.items.length > 0
            ? this.activeConversationFiles.items[this.activeConversationFiles.items.length - 1].createdAt
            : undefined,
      });

      this.activeConversationFiles.fetching = false;

      if (result.code !== 1) {
        return;
      }
      this.activeConversationFiles.items = this.activeConversationFiles.items.concat(result.data.items);
      this.activeConversationFiles.isMore = result.data.items.length === result.data.limit;
    },
    async getConversationLink (search?: string) {
      if (search) {
        this.activeConversationLink.searchItems = [];
        const result = await getConversationLinkListUseCase({
          conversationId: this.selectedConversation.id,
          search,
        });
        this.activeConversationLink.searchItems = result.data.linkInfo.map((item) => ({
          ...item,
          metadata: {
            image: "",
            description: "",
            title: "",
            loading: false,
          },
        }));

        return;
      }

      if (this.activeConversationLink.isCall) {
        return;
      }

      const fileResult = await getConversationLinkListUseCase({
        conversationId: this.selectedConversation.id,
        createdAtAfter: this.activeConversationLink.items.length > 0 ? this.activeConversationLink.items[0].createdAt : undefined,
      });

      if (fileResult.code === 1) {
        this.activeConversationLink.isCall = true;
        this.activeConversationLink.isMore = fileResult.data.linkInfo.length === fileResult.data.limit;
        this.activeConversationLink.items = fileResult.data.linkInfo.map((item) => ({
          ...item,
          metadata: {
            image: "",
            description: "",
            title: "",
            loading: false,
          },
        }));

        for (let index = 0; index < this.activeConversationLink.items.length; index++) {
          if (!this.activeConversationLink.items[index].metadata.image) {
            this.activeConversationLink.items[index].metadata.loading = true;

            getLinkMetadataUseCase(this.activeConversationLink.items[index].url).then((result) => {
              this.activeConversationLink.items[index].metadata.image = result?.image || "";
              this.activeConversationLink.items[index].metadata.description = result?.description || "";
              this.activeConversationLink.items[index].metadata.title = result?.title || "";
              this.activeConversationLink.items[index].metadata.loading = false;
            });
          }
        }
      }
    },
    async getMoreConversationLink () {
      this.activeConversationLink.fetching = true;

      const result = await getConversationLinkListUseCase({
        conversationId: this.selectedConversation.id,
        createdAtBefore:
          this.activeConversationLink.items.length > 0 ? this.activeConversationLink.items[this.activeConversationLink.items.length - 1].createdAt : undefined,
      });

      this.activeConversationLink.fetching = false;

      if (result.code !== 1) {
        return;
      }

      this.activeConversationLink.isMore = result.data.linkInfo.length === result.data.limit;
      this.activeConversationLink.items = this.activeConversationLink.items.concat(
        result.data.linkInfo.map((item) => ({
          ...item,
          metadata: {
            image: "",
            description: "",
            title: "",
            loading: false,
          },
        }))
      );
    },
    updateLastSeenMessage (data: UpdateConversationDetailsBody) {
      if (data.readAll === true) {
        data.mentionMessages = [];
        updateConversationDetailsUseCase(data);

        return;
      }

      if (data.lastSeenAt && data.lastSeenAt <= this.serverConversationDetails.lastSeenAt) {
        return;
      }
      // console.log("updateLastSeenMessage", data);

      if (data.lastSeenAt) {
        this.serverConversationDetails.lastSeenAt = data.lastSeenAt;
      }

      if (data.lastSeenMessageId) {
        this.serverConversationDetails.lastSeenMessageId = data.lastSeenMessageId;
      }

      updateConversationDetailsUseCase(data);
    },
    updateMentionMessages (messageIds: string[]) {
      messageIds.forEach((id) => {
        if (this.selectedConversation.conversationDetails.mentionMessages.find((item) => item.id === id)) {
          this.selectedConversation.conversationDetails.mentionMessages = this.selectedConversation.conversationDetails.mentionMessages.filter(
            (item) => item.id !== id
          );
        }
      });

      updateConversationDetailsUseCase({
        conversationId: this.selectedConversation.id,
        mentionMessages: this.selectedConversation.conversationDetails.mentionMessages,
      });
    },
    async removeMember (member: IUser) {
      const result = await removeMemberUseCase({ id: this.selectedConversation.id, members: [member.username] });

      if (result.code !== 1) return;
      this.activeConversationMember.items = this.activeConversationMember.items.filter((item) => item.username !== member.username);
      this.alerts = this.alerts.concat(result.data.alerts);

      scrollToEndConversation();
    },
    async renameGroup () {
      const result = await updateConversationNameUseCase({ id: this.selectedConversation.id, name: this.selectedConversation.name });

      if (result.code !== 1) return;

      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    async addMember (members: IUser[]) {
      const result = await addConversationMemberUseCase({ id: this.selectedConversation.id, members: members.map((item) => item.username) });

      if (result.code !== 1) return;

      this.activeConversationMember.items = this.activeConversationMember.items.concat(
        members.map((item) => ({ ...item, role: "member", roleText: "Member" }))
      );

      useChatDialogStateStore().closeContactModal();

      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    async leaveGroup (id: string) {
      const result = await leaveConversationUseCase({ id });

      if (result.code !== 1) return;

      if (this.selectedConversation.id === id) {
        this.selectedConversation = {} as Conversation;
      }

      this.data.conversations.items = this.data.conversations.items.filter((item) => item.id !== id);
      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    async searchMessageWithinConversation (query: SearchMessageWithinConversationQuery) {
      const result = await searchMessageWithinConversationUseCase(query);

      if (result.code !== 1) return [] as SearchMessageItem[];

      return result.data.items;
    },
    async muteNotification (conversation: Conversation, isMute: boolean) {
      if (this.selectedConversation.id === conversation.id) {
        this.selectedConversation.conversationDetails.isMute = isMute;
      }

      if (conversation.category === ConversationCategoryEnum.normal) {
        const index = this.data.conversations.items.findIndex((item) => item.id === conversation.id);

        if (index >= 0) {
          this.data.conversations.items[index].conversationDetails.isMute = isMute;
        }
      } else if (conversation.category === ConversationCategoryEnum.important) {
        const index = this.data.importantConversations.findIndex((item) => item.id === conversation.id);

        if (index >= 0) {
          this.data.pinConversations[index].conversationDetails.isMute = isMute;
        }
      } else if (conversation.category === ConversationCategoryEnum.pin) {
        const index = this.data.pinConversations.findIndex((item) => item.id === conversation.id);

        if (index >= 0) {
          this.data.pinConversations[index].conversationDetails.isMute = isMute;
        }
      }

      updateConversationDetailsUseCase({ isMute, conversationId: conversation.id });
    },
    async pinConversation (conversation: Conversation, isPin: boolean) {
      // {PIN IN FOLDER CONVERSATION}
      if (this.data.folder.activeId) {
        if (isPin === true) {
          // pin
          const pinItem = this.data.folder.conversationList.find((item) => item.id === conversation.id);

          if (!pinItem) return;

          pinItem.conversationDetails.isPin = true;
          this.data.folder.conversationList = this.data.folder.conversationList.filter((item) => item.id !== conversation.id);
          this.data.folder.pinList.unshift(pinItem);
          updatePinConversationUseCase({
            action: "add", // truyền toàn bộ ds pin
            pinFolder: {
              folderId: this.data.folder.activeId,
              conversationIds: this.data.folder.pinList.map((item) => item.id).reverse(),
            },
          });
        } else {
          // unpin
          const unPinItem = this.data.folder.pinList.find((item) => item.id === conversation.id);

          if (!unPinItem) return;

          unPinItem.conversationDetails.isPin = false;
          this.data.folder.pinList = this.data.folder.pinList.filter((item) => item.id !== conversation.id);
          this.data.folder.conversationList.unshift(unPinItem);
          updatePinConversationUseCase({
            action: "remove", // xóa cái nào thì truyền cái đó
            pinFolder: {
              folderId: this.data.folder.activeId,
              conversationIds: [conversation.id],
            },
          });
        }

        return;
      }

      // {PIN IN ALL CONVERSATION}
      if (isPin === true) {
        // pin
        const pinItem = this.data.conversations.items.find((item) => item.id === conversation.id);

        if (!pinItem) return;

        pinItem.conversationDetails.isPin = true;
        this.data.pinConversations.unshift(pinItem);
        updatePinConversationUseCase({
          action: "add", // truyền toàn bộ ds pin
          pinAll: this.data.pinConversations.map((item) => item.id).reverse(),
        });

        this.data.conversations.items = this.data.conversations.items.filter((item) => item.id !== conversation.id);
      } else {
        // unpin
        const unPinItem = this.data.pinConversations.find((item) => item.id === conversation.id);

        if (!unPinItem) return;

        unPinItem.conversationDetails.isPin = false;
        this.data.pinConversations = this.data.pinConversations.filter((item) => item.id !== conversation.id);
        this.data.conversations.items.unshift(unPinItem);
        updatePinConversationUseCase({
          action: "remove",
          pinAll: [conversation.id], // xóa cái nào thì truyền cái đó
        });
      }
    },
    async removeConversation (conversationId: string) {
      removeConversationUseCase(conversationId);

      if (this.selectedConversation.id === conversationId) {
        this.selectedConversation = {} as Conversation;
      }

      this.data.conversations.items = this.data.conversations.items.filter((item) => item.id !== conversationId);
    },
    // local

    async setSelectConversation (
      conversation: Conversation,
      lastSeenMessage?: {
        id: string;
        createdAt: Date;
      }
    ) {
      const chatDialogStateStore = useChatDialogStateStore();
      const appStateStore = useAppStateStore();
      const messageStore = useMessageStore();
      const audioStore = useAudioStateStore();

      audioStore.resetTempTrackList();
      this.selectedConversation = this.conversationList.find((item) => item.id === conversation.id) || conversation;
      // console.log("this.selectedConversation", this.selectedConversation);
      // get member info do member info trong detail mới có
      this.selectedConversation.membersInfo = conversation.membersInfo;

      this.activeConversationMedia = { items: [], isCall: false, fetching: false, isMore: false, searchItems: [] };
      this.activeConversationFiles = { items: [], isCall: false, fetching: false, isMore: false, searchItems: [] };
      this.activeConversationLink = { items: [], isCall: false, fetching: false, isMore: false, searchItems: [] };
      this.activeConversationMember = { items: [], isCall: false, page: 1, fetching: false, isMore: false, searchItems: [] };

      chatDialogStateStore.$reset();
      appStateStore.setMiddleColumnSlot("chat");
      chatDialogStateStore.hideSelectedMessage();
      messageStore.getPinMessage(this.selectedConversation.id);

      if (lastSeenMessage?.id && lastSeenMessage?.createdAt) {
        messageStore.getMessageList({ conversationId: this.selectedConversation.id, createdAt: lastSeenMessage?.createdAt }, lastSeenMessage?.id);
      } else {
        messageStore.getMessageList(
          { conversationId: this.selectedConversation.id, createdAt: this.selectedConversation.conversationDetails.lastSeenAt },
          this.selectedConversation.conversationDetails.lastSeenMessageId
        );
      }
    },
    updateLocalConversationLastMessage (conversation: Conversation, message: IMessage) {
      // duplicate hoppj thoại nếu chat bên folder thì bên all bbị add thêm
      const currentConversation = this.conversationList.find((item) => item.id === conversation.id);
      const mentionMessages = [];

      if (message.mention.length && message.mention.find((item) => item.username === useAccountStore().loginUser.username)) {
        mentionMessages.push({ id: message.id, createdAt: message.createdAt });
      }

      // nếu hộp thoại chưa tồn tại trong danh sách local
      if (!currentConversation) {
        conversation.lastMessageInfo = message;
        conversation.category = ConversationCategoryEnum.normal;
        // vì ds pin và important lúc nào cũng tồn tại trong ds nên update normal thôi
        conversation.conversationDetails.mentionMessages = mentionMessages;
        this.data.conversations.items.unshift(conversation);

        return;
      }

      // nếu đã tồn tại trong danh sách local

      // update selected conversation
      if (conversation.id === this.selectedConversation.id) {
        this.selectedConversation.lastMessageInfo = message;
      }

      // update last message
      if (currentConversation.category === ConversationCategoryEnum.normal) {
        const index = this.data.conversations.items.findIndex((item) => item.id === conversation.id);

        if (index >= 0) {
          this.data.conversations.items[index].lastMessageInfo = message;
          this.data.conversations.items[index].unreadCount = conversation.unreadCount;
          this.data.conversations.items[index].conversationDetails.mentionMessages =
            this.data.conversations.items[index].conversationDetails.mentionMessages.concat(mentionMessages);

          /* update position */
          if (index === 0) return;
          this.data.conversations.items.splice(index, 1);
          this.data.conversations.items.unshift(currentConversation);
        }
      } else if (currentConversation.category === ConversationCategoryEnum.pin) {
        const index = this.data.pinConversations.findIndex((item) => item.id === conversation.id);

        if (index >= 0) {
          this.data.pinConversations[index].lastMessageInfo = message;
          this.data.pinConversations[index].unreadCount = conversation.unreadCount;
          this.data.pinConversations[index].conversationDetails.mentionMessages =
            this.data.pinConversations[index].conversationDetails.mentionMessages.concat(mentionMessages);

          /* update position */
          if (index === 0) return;
          this.data.pinConversations.splice(index, 1);
          this.data.pinConversations.unshift(currentConversation);
        }
      } else if (currentConversation.category === ConversationCategoryEnum.important) {
        const index = this.data.importantConversations.findIndex((item) => item.id === conversation.id);

        if (index >= 0) {
          this.data.importantConversations[index].lastMessageInfo = message;
          this.data.importantConversations[index].unreadCount = conversation.unreadCount;
          this.data.importantConversations[index].conversationDetails.mentionMessages =
            this.data.importantConversations[index].conversationDetails.mentionMessages.concat(mentionMessages);

          /* update position */
          if (index === 0) return;
          this.data.importantConversations.splice(index, 1);
          this.data.importantConversations.unshift(currentConversation);
        }
      }
    },
    updateLastMessageStatus (message: IMessage) {
      const currentConversation = this.conversationList.find((item) => item.id === message.conversationId);

      if (currentConversation && currentConversation.lastMessageInfo?.id === message.id) {
        currentConversation.lastMessageInfo.seen = true;
      }
    },
    updateLocalLastSeenMessage (data: {
      conversationId: string;
      conversationUnreadCount: number;
      updateCount: number;
      action: "update" | "minus";
      updateTotalUnrealCount?: number;
      lastSeenAt?: Date;
      lastSeenMessageId?: string;
    }) {
      const messageStore = useMessageStore();
      const folderStore = useFolderStore();

      let currentUnreadCount = data.conversationUnreadCount;

      if (data.action === "minus") {
        currentUnreadCount = data.conversationUnreadCount - data.updateCount;
        messageStore.updateLocalTotalUnreadCount(messageStore.unreadCount - data.updateCount);
      } else {
        if (data.updateTotalUnrealCount !== undefined && data.updateTotalUnrealCount !== null && data.updateTotalUnrealCount >= 0) {
          messageStore.updateLocalTotalUnreadCount(data.updateTotalUnrealCount);
        }

        if (data.updateCount === 0) {
          currentUnreadCount = 0;
        }
      }

      currentUnreadCount = currentUnreadCount >= 0 ? currentUnreadCount : 0;

      if (currentUnreadCount === 0) {
        folderStore.updateFolderUnreadCount({ conversationId: data.conversationId, action: "minus", updateCount: 1 });
      }

      // update local list
      const currentConversation = this.conversationList.find((item) => item.id === data.conversationId);

      if (currentConversation && currentConversation.category === ConversationCategoryEnum.normal) {
        let index = -1;

        if (this.data.folder.activeId) {
          index = this.data.folder.conversationList.findIndex((item) => item.id === data.conversationId);
        } else {
          index = this.data.conversations.items.findIndex((item) => item.id === data.conversationId);
        }

        this.data.conversations.items[index].unreadCount = currentUnreadCount;

        if (data.lastSeenAt) {
          this.data.conversations.items[index].conversationDetails.lastSeenAt = data.lastSeenAt;
        }

        if (data.lastSeenMessageId) {
          this.data.conversations.items[index].conversationDetails.lastSeenMessageId = data.lastSeenMessageId;
        }
      } else if (currentConversation && currentConversation.category === ConversationCategoryEnum.pin) {
        let index = -1;

        if (this.data.folder.activeId) {
          index = this.data.folder.pinList.findIndex((item) => item.id === data.conversationId);
        } else {
          index = this.data.pinConversations.findIndex((item) => item.id === data.conversationId);
        }
        this.data.pinConversations[index].unreadCount = currentUnreadCount;

        if (data.lastSeenAt) {
          this.data.pinConversations[index].conversationDetails.lastSeenAt = data.lastSeenAt;
        }

        if (data.lastSeenMessageId) {
          this.data.pinConversations[index].conversationDetails.lastSeenMessageId = data.lastSeenMessageId;
        }
      } else if (currentConversation && currentConversation.category === ConversationCategoryEnum.important) {
        const index = this.data.importantConversations.findIndex((item) => item.id === data.conversationId);
        this.data.importantConversations[index].unreadCount = currentUnreadCount;

        if (data.lastSeenAt) {
          this.data.importantConversations[index].conversationDetails.lastSeenAt = data.lastSeenAt;
        }

        if (data.lastSeenMessageId) {
          this.data.importantConversations[index].conversationDetails.lastSeenMessageId = data.lastSeenMessageId;
        }
      }

      const currentConversationFolderIndex = this.data.folder.conversationList.findIndex((item) => item.id === data.conversationId);

      if (currentConversationFolderIndex >= 0) {
        this.data.folder.conversationList[currentConversationFolderIndex].unreadCount = currentUnreadCount;
      }

      // update selected conversation
      if (this.selectedConversation.id === data.conversationId) {
        this.selectedConversation.unreadCount = currentUnreadCount;

        if (data.lastSeenAt) {
          this.selectedConversation.conversationDetails.lastSeenAt = data.lastSeenAt;
        }

        if (data.lastSeenMessageId) {
          this.selectedConversation.conversationDetails.lastSeenMessageId = data.lastSeenMessageId;
        }
      }
    },
    markAsRead (conversation: Conversation) {
      const messageStore = useMessageStore();
      this.updateLocalLastSeenMessage({
        conversationId: conversation.id,
        conversationUnreadCount: conversation.unreadCount,
        updateCount: 0,
        action: "update",
        updateTotalUnrealCount: messageStore.unreadCount - conversation.unreadCount,
        lastSeenAt: conversation.lastMessageInfo.createdAt,
        lastSeenMessageId: conversation.lastMessageInfo.id,
      });

      this.updateLastSeenMessage({
        readAll: true,
        conversationId: conversation.id,
        lastSeenAt: conversation.lastMessageInfo.createdAt,
        lastSeenMessageId: conversation.lastMessageInfo.id,
      });
    },
  },
});

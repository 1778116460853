import { MessageStatus } from "@domain/constant/message-constant";
import { ICreateMessageParams } from "@domain/interfaces/message-interface";
import { defineStore } from "pinia";
import { useMessageStore } from "./message-store";

import createMessageUseCase from "@domain/use-cases/message/create-message";

export const useLocalStorageStore = defineStore("cacheStorage", {
  state: () => ({
    actions: [] as {
      key: "retrySendMessage" | string;
      id: string;
      data: any;
    }[],
    draftConversation: [] as { conversationId: string; content: string }[],
  }),
  getters: {},
  persist: true,
  actions: {
    async handleAction () {
      // this.actions = this.actions.filter((n) => n);
      // const errorActions: any = [];
      // console.log("start", this.actions, errorActions);
      // for (let index = 0; index < this.actions.length; index++) {
      //   const action = this.actions[index];
      //   let result = null;
      //   if (action.key === "retrySendMessage" && action.data) {
      //     if (action.data.record && action.data.record.base64) {
      //       // console.log("string", action.data.record.blob);
      //       result = await createMessageUseCase({
      //         ...action.data,
      //         record: {
      //           blob: getBlobFromBase64(action.data.record.base64),
      //           base64: action.data.record.base64,
      //           duration: action.data.record.duration,
      //         },
      //       });
      //     } else {
      //       result = await createMessageUseCase(action.data);
      //     }
      //     if (result) {
      //       useMessageStore().onMessageSuccess(result);
      //     } else {
      //       errorActions.push(action);
      //     }
      //   }
      // }
      // this.actions = errorActions;
      // console.log("end", this.actions, errorActions);
    },
    async addRetrySendMessage (data: ICreateMessageParams) {
      const newRetryMessage = structuredClone({
        key: "retrySendMessage",
        id: data.requestId,
        data,
      });

      this.actions = this.actions.concat([newRetryMessage]);
    },
    removeRetrySendMessage (requestId: string) {
      this.actions = this.actions.filter((item) => item.id !== requestId);
    },
    async retrySendMessage (requestId = "") {
      this.actions = this.actions.filter((n) => n);

      const item = this.actions.find((item) => item.id === requestId);
      const message = useMessageStore().data.find((item) => item.requestId === requestId);

      if (item && message) {
        message.status = MessageStatus.sending;
        const result = await createMessageUseCase(item.data);

        if (result) {
          useMessageStore().onMessageSuccess(result);
          this.removeRetrySendMessage(requestId);
        } else {
          message.status = MessageStatus.error;
        }
      }
    },
    updateDraftConversation (data: { conversationId: string; content: string }) {
      const index = this.draftConversation.findIndex((item) => item.conversationId === data.conversationId);

      if (index >= 0) {
        if (data.content) {
          this.draftConversation[index] = data;

          return;
        }

        this.draftConversation = this.draftConversation.filter((item) => item.conversationId !== data.conversationId);

        return;
      }

      if (data.content && data.conversationId) {
        this.draftConversation.push(data);
      }
    },
    getDraft (conversationId: string) {
      const draftItem = this.draftConversation.find((item) => item.conversationId === conversationId);

      return draftItem?.content || "";
    },
    removeDraft (conversationId: string) {
      this.draftConversation = this.draftConversation.filter((item) => item.conversationId !== conversationId);
    },
  },
});

export const DMY_HMS = "DD/MM/YYYY hh:mm:ss";

export const DMY_HM = "DD/MM/YYYY hh:mm";

export const YMD = "YYYY/MM/DD";

export const MDY = "MM/DD/YYYY";

export const HMS = "hh:mm:ss";

export const DMY = "DD/MM/YYYY";

export const YMD_HMS = "YYYY-MM-DD hh:mm:ss";

export const HM = "hh:mm";

export function getEndDay (input?: string | Date) {
  const date = input ? new Date(input) : new Date();
  date.setHours(23, 59, 59, 999);

  return date;
}

export function getStartDay (input?: string | Date) {
  const date = input ? new Date(input) : new Date();
  date.setHours(0, 0, 0, 0);

  return date;
}

export function dateToString (input?: Date | string, format?: string) {
  if (!input) return "";

  const fullTime = new Date(input);
  const month = fullTime.getMonth() + 1;
  const date = fullTime.getDate();
  const year = fullTime.getFullYear();
  const hours = fullTime.getHours();
  const minutes = fullTime.getMinutes();
  const seconds = fullTime.getSeconds();

  const time = {
    year,
    date: date < 10 ? `0${date}` : date,
    month: month < 10 ? `0${month}` : month,
    hour: hours < 10 ? `0${hours}` : hours,
    minute: minutes < 10 ? `0${minutes}` : minutes,
    seconds: seconds < 10 ? `0${seconds}` : seconds,
  };

  if (!format || format === DMY) return `${time.date}/${time.month}/${time.year}`;

  if (format === DMY_HMS) {
    return `${time.date}/${time.month}/${time.year} ${time.hour}:${time.minute}:${time.seconds}`;
  }

  if (format === DMY_HM) {
    return `${time.date}/${time.month}/${time.year} ${time.hour}:${time.minute}`;
  }

  if (format === YMD) return `${time.year}/${time.month}/${time.date}`;

  if (format === MDY) return `${time.month}/${time.date}/${time.year}`;

  if (format === HMS) return `${time.hour}:${time.minute}:${time.seconds}`;

  if (format === YMD_HMS) {
    return `${time.year}/${time.month}/${time.date} ${time.hour}:${time.minute}:${time.seconds}`;
  }

  if (format === MDY) return `${time.month}/${time.date}/${time.year}`;

  if (format === HM) return `${time.hour}:${time.minute}`;

  return "";
}

export function getDiffTimeText (time: any, format?: string) {
  let inputTime = time;

  if (format === DMY) inputTime = time.split("/").reverse().join("-");

  const createdTime = new Date(inputTime);
  const now = new Date();
  const diffTime = now.getTime() - createdTime.getTime();
  const diffSecond = Math.ceil(diffTime / 1000);
  const diffMinutes = Math.ceil(diffTime / (1000 * 60));
  const diffHour = Math.ceil(diffTime / (1000 * 60 * 60));
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffSecond < 60) return `${diffSecond} giây trước`;

  if (diffMinutes < 60) return `${diffMinutes} phút trước`;

  if (diffHour <= 24) return `${diffHour} giờ trước`;

  if (diffDays > 1 && diffDays < 7) {
    return `${diffDays} ngày`;
  }

  return dateToString(createdTime);
}

export function getTimer (ms?: number) {
  if (!ms) return "0:00,000";
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(ms / (1000 * 60));
  const hours = Math.floor(ms / (1000 * 60 * 60));
  const milliseconds = Math.floor((ms % 1000) / 10); // Get 2-digit milliseconds

  const secondsString = seconds < 10 ? `0${seconds}` : seconds.toString();
  const formattedMilliseconds = milliseconds < 10 ? "0" + milliseconds : milliseconds;

  let timer = `[hour]:[minutes]:${secondsString},${formattedMilliseconds}`;

  timer = timer.replace("[minutes]", minutes.toString());

  if (hours > 0) {
    timer = timer.replace("[hour]", hours.toString());
  } else {
    timer = timer.replace("[hour]:", "");
  }

  return timer;
}

export function getTimerBySeconds (inputSeconds?: number) {
  if (!inputSeconds) return "0:00";

  // inputSeconds = Math.round(inputSeconds);
  // console.log(Math.round(inputSeconds));
  // const hours = Math.round(inputSeconds / 3600);
  // const minutes = Math.round((inputSeconds % 3600) / 60);
  // const seconds = Math.round(inputSeconds);

  // const secondsString = seconds < 10 ? `0${seconds}` : Math.round(seconds).toString();
  // let timer = `[hour]:[minutes]:${secondsString}`;

  // timer = timer.replace("[minutes]", minutes.toString());

  // if (hours > 0) {
  //   timer = timer.replace("[hour]", hours.toString());
  // } else {
  //   timer = timer.replace("[hour]:", "");
  // }

  // return timer;

  const hours = Math.floor(inputSeconds / 3600); // Lấy số giờ
  const minutes = Math.floor((inputSeconds % 3600) / 60); // Lấy số phút
  const remainingSeconds = Math.floor(inputSeconds % 60); // Lấy số giây còn lại

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  } else {
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  }
}

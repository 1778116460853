import getUserListUseCase from "@domain/use-cases/user/get-list";

import { IGetUserListQuery, IUser } from "@domain/interfaces/user-interface";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    contact: {
      fetching: false,
      items: [] as IUser[],
      total: 0,
      limit: 10,
      page: 1,
      isMore: false,
    },
  }),
  persist: false,
  actions: {
    async getUserList (query: IGetUserListQuery) {
      this.contact.fetching = true;
      this.contact.page = 1;
      const result = await getUserListUseCase({ search: query.search, page: this.contact.page });

      if (result.code !== 1) return;
      this.contact.fetching = false;
      this.contact.items = result.data.items;
      this.contact.total = result.data.total;
      this.contact.limit = result.data.limit;
      this.contact.isMore = result.data.items.length === result.data.limit;
    },

    async getMoreUserList (query: IGetUserListQuery) {
      this.contact.fetching = true;
      this.contact.page = this.contact.page + 1;

      const result = await getUserListUseCase({ search: query.search, page: this.contact.page });

      if (result.code !== 1) return;
      this.contact.fetching = false;
      this.contact.items = this.contact.items.concat(result.data.items);
      this.contact.total = result.data.total;
      this.contact.limit = result.data.limit;
      this.contact.isMore = result.data.items.length === result.data.limit;
    },
  },
});

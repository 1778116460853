import { DEFAULT_TIMEOUT } from "@common/constant";
import { SpeechToTextBody, SpeechToTextResponse } from "@domain/interfaces/attachment-interface";
import { socket } from "@infrastructure/network/socket";
import { injectable } from "inversify";

import AttachmentRepository from "@domain/repositories/attachment-repository";

@injectable()
export default class AttachmentApiRepository implements AttachmentRepository {
  async speechToText (data: SpeechToTextBody): Promise<SpeechToTextResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("attachment:speechToText", data, (error, data: SpeechToTextResponse) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }
}

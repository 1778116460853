import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["height", "width", "src"]


export default /*@__PURE__*/_defineComponent({
  __name: 'AppIcon',
  props: {
    size: {},
    src: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    height: _ctx.size || 24,
    width: _ctx.size || 24,
    src: _ctx.src
  }, null, 8, _hoisted_1))
}
}

})
import FolderRepository from "@domain/repositories/folder-repository";

import { DEFAULT_TIMEOUT } from "@common/constant";
import { ICreateFolderBody, ICreateFolderResponse, IGetFolderListQuery, IGetFolderListResponse, IUpdateFolderBody } from "@domain/interfaces/folder-interface";
import { socket } from "@infrastructure/network/socket";
import { injectable } from "inversify";

@injectable()
export default class FolderSocketRepository implements FolderRepository {
  getList (query: IGetFolderListQuery): Promise<IGetFolderListResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("folder:getList", query, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  createFolder (body: ICreateFolderBody): Promise<ICreateFolderResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("folder:create", body, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  removeFolder (id: string): Promise<ICreateFolderResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("folder:remove", { id }, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  updateFolder (body: IUpdateFolderBody): Promise<ICreateFolderResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("folder:update", body, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }
}

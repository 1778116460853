import createClientMessageUseCase from "@domain/use-cases/message/create-client-message";
import createMessageUseCase from "@domain/use-cases/message/create-message";
import editMessageUseCase from "@domain/use-cases/message/edit-message";
import getCountUnreadMessageUseCase from "@domain/use-cases/message/get-count-unread-message";
import getMessageMentionUserUseCase from "@domain/use-cases/message/get-mention-user";
import getMessageCountUseCase from "@domain/use-cases/message/get-message-count";
import getMessageListUseCase from "@domain/use-cases/message/get-message-list";
import getPinMessageUseCase from "@domain/use-cases/message/get-pin-message";
import pinMessageUseCase from "@domain/use-cases/message/pin-message";
import reactionMessageUseCase from "@domain/use-cases/message/reaction-message";
import removeMessageUseCase from "@domain/use-cases/message/remove-message";
import unpinMessageUseCase from "@domain/use-cases/message/unpin-message";

import Message from "@domain/entities/message-entity";

import { MessageStatus } from "@domain/constant/message-constant";
import { getContent, getParseMode, isNewest, scrollToEndConversation, scrollToMessage } from "@domain/helpers/message-helper";
import { SpeechToTextBody } from "@domain/interfaces/attachment-interface";
import {
  GetMessageCountQuery,
  GetMessageDetailResponse,
  GetMessageListQuery,
  GetUserSeenMessageQuery,
  IMessage,
  IRecordInfo,
  MessageActions,
  MessageSticker,
  PinMessageBody,
  QuoteInfo,
  ReactionMessageBody,
  ReplyBotMessageBody,
  UnPinMessageBody,
} from "@domain/interfaces/message-interface";
import speechToTextUseCase from "@domain/use-cases/conversation/attachment/speech-to-text";
import getUserSeenMessageUseCase from "@domain/use-cases/message/get-user-seen-message";
import replyToBotMessageUseCase from "@domain/use-cases/message/reply-bot-message";
import { defineStore } from "pinia";
import { useAlertStore } from "./alert-store";
import { useAppStateStore } from "./app-store";
import { useAudioStateStore } from "./audio-store";
import { useConversationStore } from "./conversation-store";
import { useLocalStorageStore } from "./local-storage-store";

export const useMessageStore = defineStore("message", {
  state: () => ({
    page: 1,
    fetching: false,
    data: [] as IMessage[],
    total: 0,
    limit: 10,
    newestMessageId: "",
    currentMessageId: "",
    searchQuery: <GetMessageListQuery>{},
    isBefore: false, // còn tin nhắn chưa load
    isMoreFetching: false,
    wheel: {
      up: false,
      active: false,
    },
    unreadCount: 0,
    highlightMessageId: "",
    pin: {
      list: [] as IMessage[],
      active: undefined as IMessage | undefined,
      loading: false,
    },
    selectedMessages: [] as IMessage[],
    selectMessageVisible: false,
    readList: [] as IMessage[],
    readListTimeout: undefined as any,
  }),
  getters: {},
  actions: {
    async getMessageList (query: GetMessageListQuery, highlightMessageId?: string) {
      const conversationStore = useConversationStore();

      this.fetching = true;
      this.searchQuery = query;
      this.data = [];
      this.page = 1;
      this.isBefore = true;
      this.newestMessageId = "";
      this.wheel.up = false;
      this.wheel.active = false;

      if (query.conversationId !== conversationStore.selectedConversation.id) {
        this.pin.list = [];
        this.pin.active = undefined;
      }
      const result = await getMessageListUseCase(query);

      if (result.code !== 1) {
        return;
      }

      if (query.createdAt || query.createdAtBefore) {
        this.isBefore = result.data.items?.length > 0;
      }

      this.data = result.data.items;
      this.newestMessageId = result.data?.newestMessageId;
      this.fetching = false;
      this.wheel.active = true;

      if (!isNewest(this.data, conversationStore.selectedConversation)) {
        this.wheel.up = true;
      }

      if (highlightMessageId) {
        this.highlightMessageId = highlightMessageId;
      }

      if (this.highlightMessageId) {
        scrollToMessage(this.highlightMessageId);
      } else if (conversationStore.selectedConversation.conversationDetails.lastSeenMessageId === this.newestMessageId) {
        scrollToMessage(conversationStore.selectedConversation.conversationDetails?.lastSeenMessageId);
      }
    },
    async getMoreMessageList (query: GetMessageListQuery, type: "before" | "after") {
      if (this.isMoreFetching) {
        return;
      }

      this.isMoreFetching = true;
      this.wheel.active = false;
      const result = await getMessageListUseCase({
        conversationId: query.conversationId,
        createdAtBefore: type === "before" && this.data.length > 0 ? this.data[0].createdAt : undefined,
        createdAtAfter: type === "after" && this.data.length > 0 ? this.data[this.data.length - 1]?.createdAt : undefined,
      });

      this.isMoreFetching = false;

      if (result.code !== 1) {
        return;
      }

      const items = result.data.items;

      if (type === "before") {
        this.data = items.concat(this.data);
        this.isBefore = result.data.items?.length === result.data.limit;
      } else if (type === "after") {
        this.data = this.data.concat(items);
      }
      this.wheel.active = true;
      this.page = this.page + 1;
      this.newestMessageId = result.data?.newestMessageId;
    },
    async createMessage (
      message: {
        content: string;
        files?: File[];
        action?: MessageActions;
        rootMessage?: IMessage;
        quote?: QuoteInfo;
        record?: IRecordInfo;
        sticker?: MessageSticker;
      },
      conversationId?: string
    ) {
      const conversationStore = useConversationStore();
      const messageStore = useMessageStore();
      const localStorageStore = useLocalStorageStore();

      const sentConversationId = conversationId || conversationStore.selectedConversation.id;
      const mention = getMessageMentionUserUseCase(message.content, conversationStore.selectedConversation);
      // create client message
      const clientMessage = createClientMessageUseCase({
        requestId: new Date().getTime().toString(),
        content: message.content,
        conversationId: sentConversationId,
        files: message.files,
        action: message.action,
        rootMessage: message.rootMessage,
        mention,
        quote: message.quote,
        record: message.record,
        sticker: message.sticker,
      });

      conversationStore.updateLocalConversationLastMessage(conversationStore.selectedConversation, clientMessage);
      conversationStore.updateLocalLastSeenMessage({
        conversationId: conversationStore.selectedConversation.id,
        conversationUnreadCount: conversationStore.selectedConversation.unreadCount,
        updateCount: 0,
        action: "update",
        updateTotalUnrealCount: messageStore.unreadCount - conversationStore.selectedConversation.unreadCount,
        lastSeenAt: conversationStore.selectedConversation.lastMessageInfo.createdAt,
        lastSeenMessageId: conversationStore.selectedConversation.lastMessageInfo.id,
      });

      // lấy tin nhắn mới nhất của hộp thoại
      if (!isNewest(messageStore.data, conversationStore.selectedConversation)) {
        const newestMessageResult = await getMessageListUseCase({ conversationId: sentConversationId });

        if (newestMessageResult.data.items) {
          this.data = newestMessageResult.data.items.concat([clientMessage]);
        }
      } else {
        this.data = this.data.concat([clientMessage]);
      }
      scrollToEndConversation();
      localStorageStore.removeDraft(sentConversationId);
      // send client message to server
      const createMessageParams = {
        content: message.content,
        conversationId: sentConversationId,
        files: message.files,
        requestId: clientMessage.requestId,
        action: message.action,
        rootMessage: message.rootMessage,
        mention,
        quote: message.quote,
        record: message.record,
        sticker: message.sticker,
      };
      const result = await createMessageUseCase(createMessageParams);

      if (result) {
        this.onMessageSuccess(result);
      } else {
        const updateMessageIndex = this.data.findIndex((item) => item.requestId === clientMessage.requestId);

        if (updateMessageIndex >= 0) {
          this.data[updateMessageIndex].status = MessageStatus.error;
        }
      }
    },
    async createForwardMessage (forwardMessages: IMessage[], content: string) {
      const conversationStore = useConversationStore();
      // create client message
      const newMessages: IMessage[] = [];

      if (content) {
        newMessages.push(
          createClientMessageUseCase({
            requestId: new Date().getTime().toString(),
            content,
            conversationId: conversationStore.selectedConversation.id,
            action: "forward",
          })
        );
      }
      forwardMessages.forEach((message) => {
        newMessages.push(
          createClientMessageUseCase({
            requestId: new Date().getTime().toString(),
            content: "",
            conversationId: conversationStore.selectedConversation.id,
            action: "forward",
            rootMessage: message.forward.id ? message.forward : message,
          })
        );
      });

      if (!isNewest(this.data, conversationStore.selectedConversation)) {
        const newestMessageResult = await getMessageListUseCase({ conversationId: conversationStore.selectedConversation.id });

        if (newestMessageResult.data.items) {
          this.data = newestMessageResult.data.items.concat(newMessages);
        }
      } else {
        this.data = this.data.concat(newMessages);
      }

      scrollToEndConversation();

      const lastMessage = this.data[this.data.length - 1];
      conversationStore.updateLocalConversationLastMessage(conversationStore.selectedConversation, lastMessage);
      conversationStore.updateLocalLastSeenMessage({
        conversationId: conversationStore.selectedConversation.id,
        conversationUnreadCount: conversationStore.selectedConversation.unreadCount,
        updateCount: 0,
        action: "update",
        updateTotalUnrealCount: this.unreadCount - conversationStore.selectedConversation.unreadCount,
        lastSeenAt: conversationStore.selectedConversation.lastMessageInfo.createdAt,
        lastSeenMessageId: conversationStore.selectedConversation.lastMessageInfo.id,
      });

      for (let index = 0; index < newMessages.length; index++) {
        const message = newMessages[index];
        // send client message to server
        const createMessageParams = {
          content: message.content,
          conversationId: conversationStore.selectedConversation.id,
          requestId: message.requestId || "",
          action: "forward" as MessageActions,
          rootMessage: message.forward,
        };

        const result = await createMessageUseCase(createMessageParams);

        if (result) {
          this.onMessageSuccess(result);
        } else {
          const updateMessageIndex = this.data.findIndex((item) => item.requestId === message.requestId);

          if (updateMessageIndex >= 0) {
            this.data[updateMessageIndex].status = MessageStatus.error;
          }
        }
      }
    },
    async removeMessage (id: string) {
      const conversationStore = useConversationStore();
      const audioStateStore = useAudioStateStore();

      const index = this.data.findIndex((item) => item.id === id);
      this.data.splice(index, 1);
      audioStateStore.removeTrackItem({ id });

      // console.log(conversationStore.selectedConversation.lastMessageInfo?.id, id);

      if (conversationStore.selectedConversation.lastMessageInfo?.id === id) {
        conversationStore.updateLocalConversationLastMessage(conversationStore.selectedConversation, this.data[index - 1]);
      }

      removeMessageUseCase(id);
    },
    async getUnreadMessageCount () {
      const { data } = await getCountUnreadMessageUseCase();
      this.unreadCount = data.internal;
    },
    async editMessage (id: string, values: { content: string }) {
      const content = getContent(values.content);

      const editMessageValue = {
        content,
        mention: getMessageMentionUserUseCase(content, useConversationStore().selectedConversation),
        parseMode: getParseMode(content),
      };

      editMessageUseCase(id, editMessageValue);

      const editMessageIndex = this.data.findIndex((item) => item.id === id);

      if (editMessageIndex !== -1) {
        this.data[editMessageIndex].content = editMessageValue.content;
        this.data[editMessageIndex].parseMode = editMessageValue.parseMode;
        this.data[editMessageIndex].mention = editMessageValue.mention;
        this.data[editMessageIndex].isEdited = true;
        const conversationStore = useConversationStore();

        if (conversationStore.selectedConversation.lastMessageInfo.id === id) {
          conversationStore.selectedConversation.lastMessageInfo.content = editMessageValue.content;
        }
      }
    },
    async reactionMessage (data: ReactionMessageBody) {
      const result = await reactionMessageUseCase(data);

      if (result.code !== 1) return;
      this.updateLocalMessageReaction(result.data.message);
    },
    async getPinMessage (conversationId: string) {
      const result = await getPinMessageUseCase(conversationId);

      if (result.code !== 1) return;
      this.pin.list = result.data.messages?.items || [];

      if (this.pin.list.length > 0) {
        this.pin.active = this.pin.list[0];
      }
    },
    async pinMessage (body: PinMessageBody) {
      const result = await pinMessageUseCase(body);

      if (result.code !== 1) {
        useAlertStore().error(result.message);

        return;
      }

      this.pin.list.push(result.data.message);
      this.pin.active = result.data.message;
    },
    async unpinMessage (body: UnPinMessageBody) {
      const result = await unpinMessageUseCase(body);

      if (result.code !== 1) return;
      this.pin.list = this.pin.list.filter((item) => !body.messageIds.includes(item.id));

      if (this.pin.list.length === 0) {
        useAppStateStore().closePinMessage();
        this.pin.active = undefined;
      }

      if (this.pin.active?.id && body.messageIds.includes(this.pin.active.id)) {
        this.pin.active = this.pin.list[0];
      }
    },
    async unpinAllMessage () {
      this.pin.loading = true;

      const result = await unpinMessageUseCase({
        conversationId: useConversationStore().selectedConversation.id,
        messageIds: this.pin.list.map((item) => item.id),
      });

      this.pin.loading = false;

      if (result.code !== 1) {
        return useAlertStore().error(result.message);
      }
      useAppStateStore().closePinMessage();
      this.pin.list = [];
      this.pin.active = undefined;
    },
    async getMessageCount (query: GetMessageCountQuery) {
      const result = await getMessageCountUseCase(query);

      if (result.code !== 1) return 0;

      return result.data.count;
    },
    async getUserSeenMessage (query: GetUserSeenMessageQuery) {
      const result = await getUserSeenMessageUseCase(query);

      if (result.code !== 1) {
        return {
          total: 0,
          docs: [],
        };
      }

      return result.data;
    },
    async replyToBotMessage (data: ReplyBotMessageBody) {
      replyToBotMessageUseCase(data);
    },
    async speechToText (body: SpeechToTextBody) {
      const result = await speechToTextUseCase(body);

      if (!result.data.text) return { messageId: body.messageId, attachmentId: body.attachmentId, text: "" };
      const messageIndex = this.data.findIndex((item) => item.id === body.messageId);

      if (messageIndex !== -1) {
        const attachmentIndex = this.data[messageIndex].attachment.findIndex((attachment) => attachment.attachmentId === body.attachmentId);

        this.data[messageIndex].attachment[attachmentIndex].speechToText = {
          ...this.data[messageIndex].attachment[attachmentIndex].speechToText,
          text: result.data.text,
        };
      }

      return { messageId: body.messageId, attachmentId: body.attachmentId, text: result.data?.text || "" };
    },
    // local
    async getNextPinMessage () {
      if (!this.pin.active?.id) return;

      const currentIndex = this.pin.list.findIndex((item) => item.id === this.pin.active?.id);
      document.getElementById(this.pin.active.id)?.setAttribute("class", "");

      if (currentIndex < this.pin.list.length - 1) {
        this.pin.active = this.pin.list[currentIndex + 1];
      } else {
        this.pin.active = this.pin.list[0];
      }

      if (!this.data.find((item) => item.id === this.pin.active?.id)) {
        await this.getMessageList({
          conversationId: this.pin.active.conversationId,
          createdAt: this.pin.active.createdAt,
        });
      }

      this.highlightMessageId = this.pin.active.id;
      document.getElementById(this.pin.active.id)?.setAttribute("style", "background: rgba(0, 0, 0, 0.03)");
      scrollToMessage(this.pin.active.id);
      setTimeout(() => {
        this.highlightMessageId = "";

        if (this.pin.active?.id) {
          document.getElementById(this.pin.active.id)?.setAttribute("style", "");
        }
      }, 1000);

      if (currentIndex > 1) {
        const element = document.getElementById(`pin#${this.pin.active.id}`);

        element?.scrollIntoView({ behavior: "smooth", inline: "nearest" });
      }
    },
    addLocalSelectedConversationMessage (message: IMessage) {
      this.data = this.data.concat([message]);
      this.newestMessageId = message.id;
    },
    updateLocalTotalUnreadCount (count: number) {
      this.unreadCount = count >= 0 ? count : 0;
    },
    updateLocalMessage (message: IMessage) {
      const messageIndex = this.data.findIndex((item) => item.id === message.id);

      if (messageIndex !== -1) {
        this.data[messageIndex].content = message.content;
        this.data[messageIndex].parseMode = message.parseMode;
        this.data[messageIndex].mention = message.mention;
        this.data[messageIndex].isEdited = message.isEdited;
      }
    },
    updateLocalMessageReaction (message: IMessage) {
      const messageIndex = this.data.findIndex((item) => item.id === message.id);

      if (messageIndex !== -1) {
        this.data[messageIndex].reactions = message.reactions;
      }
      const pinIndex = this.pin.list.findIndex((item) => item.id === message.id);

      if (pinIndex !== -1) {
        this.pin.list[pinIndex].reactions = message.reactions;
      }
    },
    onMessageSuccess (result: GetMessageDetailResponse) {
      const conversationStore = useConversationStore();
      const audioStateStore = useAudioStateStore();

      if (result.code === 1) {
        const newMessage = new Message({ ...result.data.message, status: MessageStatus.delivered });

        const updateMessageIndex = this.data.findIndex((item) => item.requestId === result.data.requestId);
        audioStateStore.removeTrackItem({ requestId: result.data.requestId });
        this.data[updateMessageIndex] = newMessage;
        this.newestMessageId = newMessage.id;
        conversationStore.updateLocalConversationLastMessage(conversationStore.selectedConversation, newMessage);
      }
    },
    async removeLocalMessage (requestId: string) {
      this.data = this.data.filter((item) => item.requestId !== requestId);
    },
    updateReadList ({ message }: { message: IMessage }) {
      clearTimeout(this.readListTimeout);

      this.readList.push(message);
      this.readListTimeout = setTimeout(() => {
        const readCount = this.readList.length;

        if (readCount === 0) return;
        const conversationStore = useConversationStore();
        this.readList = this.readList.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        const lastItem = this.readList[readCount - 1];

        // console.log("readList", this.readList);
        // console.log(conversationStore.selectedConversation.unreadCount);
        // console.log("conversationDetails", toRaw(conversationStore.selectedConversation.conversationDetails));

        conversationStore.updateLocalLastSeenMessage({
          conversationId: conversationStore.selectedConversation.id,
          conversationUnreadCount: conversationStore.selectedConversation.unreadCount,
          updateCount: readCount,
          action: "minus",
          lastSeenAt: lastItem.createdAt,
          lastSeenMessageId: lastItem.id,
        });

        conversationStore.updateLastSeenMessage({
          lastSeenAt: lastItem.createdAt,
          lastSeenMessageId: lastItem.id,
          conversationId: lastItem.conversationId,
          readMessageCount: readCount,
        });

        conversationStore.updateMentionMessages(this.readList.map((item) => item.id));

        // nếu đã đoc đến tin cuối mà ma số tin chưa đọc vẫn còn
        if (lastItem.id === conversationStore.selectedConversation.id && conversationStore.selectedConversation.unreadCount > 0) {
          conversationStore.updateLocalLastSeenMessage({
            conversationId: conversationStore.selectedConversation.id,
            conversationUnreadCount: conversationStore.selectedConversation.unreadCount,
            updateCount: 0,
            action: "update",
            updateTotalUnrealCount: useMessageStore().unreadCount - conversationStore.selectedConversation.unreadCount,
          });
          conversationStore.updateLastSeenMessage({
            readAll: true,
            conversationId: conversationStore.selectedConversation.id,
            lastSeenAt: conversationStore.selectedConversation.lastMessageInfo?.createdAt,
            lastSeenMessageId: conversationStore.selectedConversation.lastMessageInfo?.id,
          });
        }
        // console.log("lastItem", toRaw(lastItem));
        // console.log("conversationDetails", toRaw(conversationStore.selectedConversation.conversationDetails));
        this.readList = [];
      }, 100);
    },
  },
});

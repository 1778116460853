import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { ReplyBotMessageBody } from "@domain/interfaces/message-interface";

export default function replyToBotMessageUseCase (body: ReplyBotMessageBody) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.replyToBotMessage(body);
}

import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { GetRecentContactQuery } from "@domain/interfaces/conversation-interface";

export default async function getRecentContactsUseCase (query: GetRecentContactQuery) {
  const conversationRepository = repository.get<ConversationRepository>(ConversationRepository);

  return await conversationRepository.getRecentContacts(query);
}

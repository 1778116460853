import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { TRASH_ICON } from "@common/icon-path";

export default /*@__PURE__*/_defineComponent({
  __name: 'TrashIcon',
  props: {
    size: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", { src: _unref(TRASH_ICON) }, null, 8, _hoisted_1))
}
}

})
export const ROLE = {
  owner: "owner",
  member: "member",
};

export enum ConversationCategoryEnum {
  pin = "pin",
  important = "important",
  normal = "normal",
}

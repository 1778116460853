/* eslint-disable max-len */
import { isAudio, isImage, isVideo } from "@common/file";
import { dateToString, getStartDay } from "@common/utils/date";
import Conversation from "@domain/entities/conversation-entity";
import { IMessage, MessageAttachment, MessageParseMode, MessageReaction, ViewMessages } from "@domain/interfaces/message-interface";
import { useAccountStore } from "@presentation/store/account-store";

import Axios from "axios";
import _ from "lodash";

export function groupMessageByDate (messages: IMessage[]): { date: string; messageInDay: IMessage[] }[] {
  return _(messages)
    .groupBy((mess: IMessage) => getStartDay(mess.createdAt))
    .map((messageInDay, date) => ({
      date: getStartDay(date).getTime() === getStartDay(new Date()).getTime() ? "Today" : dateToString(date),
      messageInDay,
    }))
    .valueOf();
}

function groupMessagesBySender (messages: IMessage[]) {
  const TIME_THRESHOLD = 2 * 60 * 1000; // 2 phút (miligiây)

  return messages.reduce<any>((acc, curr) => {
    const lastGroup = acc[acc.length - 1];

    if (
      lastGroup &&
      lastGroup.createdBy === curr.createdBy && // Cùng người gửi
      new Date(curr.createdAt).getTime() - new Date(lastGroup.messages[lastGroup.messages.length - 1].createdAt).getTime() < TIME_THRESHOLD // Cách nhau < 2 phút
    ) {
      lastGroup.messages.push(curr);
    } else {
      acc.push({
        createdBy: curr.createdBy,
        createdByInfo: curr.createdByInfo,
        messages: [curr],
      });
    }

    return acc;
  }, []);
}

export function getViewMessageList (messages: IMessage[]): ViewMessages[] {
  return _(messages)
    .groupBy((mess: IMessage) => getStartDay(mess.createdAt))
    .map((dateGroupItems, date) => ({
      date: getStartDay(date).getTime() === getStartDay(new Date()).getTime() ? "Today" : dateToString(date),
      dateGroupItems: groupMessagesBySender(dateGroupItems),
    }))
    .valueOf();
}

export const scrollToMessage = (id: string, scrollOptions?: ScrollIntoViewOptions, delay = 200) =>
  setTimeout(() => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView(scrollOptions || { behavior: "auto", inline: "end" });
    }
  }, delay);

export const scrollToEndConversation = (delay = 100) => {
  setTimeout(() => {
    const element = document.getElementById("conversation");

    if (element) {
      const { scrollHeight } = element;
      element.scrollBy({ top: scrollHeight + 1000, behavior: "smooth" as ScrollBehavior });
    }
  }, delay);
};

export function elementInViewport (el: any) {
  let top = el.offsetTop;
  let left = el.offsetLeft;
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  // console.log(top, left, width, height);

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    top + height <= window.pageYOffset + window.innerHeight &&
    left + width <= window.pageXOffset + window.innerWidth
  );
}

export const isOwnerMessage = (message: IMessage) => message.createdByInfo?.username === useAccountStore().loginUser.username;

export const isBotMessage = (message: IMessage) => message.createdByInfo?.type === "bot";

export function onCopyOrCutMessage (userMessage: IMessage, selectedText?: string, e?: ClipboardEvent) {
  if (isBotMessage(userMessage)) {
    e?.preventDefault();

    return;
  }

  if (selectedText) {
    navigator.clipboard.writeText(selectedText);

    return;
  }

  navigator.clipboard.writeText(userMessage.content);
}

export function groupReactionMessageByDate (reactions: MessageReaction[]) {
  return _(reactions)
    .groupBy((reaction: MessageReaction) => reaction.icon)
    .map((users, icon) => ({
      icon,
      users,
    }))
    .valueOf();
}

export const isPinnedMessage = (messageId: string, pinList: IMessage[]) => {
  return !!pinList.find((m) => m.id === messageId);
};

export const getParseMode = (content: string): MessageParseMode => {
  const htmlTags = [
    "a",
    "abbr",
    "acronym",
    "address",
    "applet",
    "area",
    "article",
    "aside",
    "audio",
    "b",
    "base",
    "bdi",
    "bdo",
    "blockquote",
    "body",
    "br",
    "button",
    "canvas",
    "caption",
    "cite",
    "code",
    "col",
    "colgroup",
    "data",
    "datalist",
    "dd",
    "del",
    "details",
    "dfn",
    "dialog",
    "div",
    "dl",
    "dt",
    "em",
    "embed",
    "fieldset",
    "figcaption",
    "figure",
    "footer",
    "form",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "head",
    "header",
    "hr",
    "html",
    "i",
    "iframe",
    "img",
    "input",
    "ins",
    "kbd",
    "label",
    "legend",
    "li",
    "link",
    "main",
    "map",
    "mark",
    "meta",
    "meter",
    "nav",
    "noscript",
    "object",
    "ol",
    "optgroup",
    "option",
    "output",
    "p",
    "param",
    "picture",
    "pre",
    "progress",
    "q",
    "rp",
    "rt",
    "ruby",
    "s",
    "samp",
    "script",
    "section",
    "select",
    "small",
    "source",
    "span",
    "strong",
    "style",
    "sub",
    "summary",
    "sup",
    "table",
    "tbody",
    "td",
    "template",
    "textarea",
    "tfoot",
    "th",
    "thead",
    "time",
    "title",
    "tr",
    "track",
    "u",
    "ul",
    "var",
    "video",
    "wbr",
  ];

  if (htmlTags.some((tag) => content.includes(`<${tag}`))) {
    return "html";
  }

  return "text";
};

export const getContent = (content: string) => {
  if (!content) return "";
  // &lt; stands for the less-than sign: <
  // &gt; stands for the greater-than sign: >
  // &le; stands for the less-than or equals sign: ≤
  // &ge; stands for the greater-than or equals sign: ≥

  return content
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&le;/g, "≤")
    .replace(/&ge;/g, "≥")
    .replace(/&nbsp;/g, " ");
};

export const getMentionsUser = (
  str: string,
  opts: {
    unique: boolean;
    nameOnly: boolean;
  }
) => {
  if (typeof str !== "string") {
    throw new TypeError("Expected a string");
  }

  opts = opts || {};
  opts.unique = opts.unique || false;

  const users = str.match(/@[a-zA-Z0-9_-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+/gim);

  if (!users) {
    return [];
  }

  if (opts.unique && opts.nameOnly) {
    return [
      ...new Set(
        users.map(function (user) {
          return user.replace(/^@/, "");
        })
      ),
    ];
  }

  if (opts.unique) {
    // return arrayUnique(users);
    return [...new Set(users)];
  }

  if (opts.nameOnly) {
    return users.map(function (user) {
      return user.replace(/^@/, "");
    });
  }

  return users;
};

export const downloadAttachment = (item: { link: string; mimetype: string; originalname: string }) => {
  Axios.get(item.link, { responseType: "blob" })
    .then((response: any) => {
      const blob = new Blob([response.data], { type: item.mimetype });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.originalname;
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(console.error);
};

export const isNewest = (messageList: IMessage[], conversation: Conversation) => {
  if (messageList.length === 0) return true;
  const listLastMessage = messageList.length > 0 ? messageList[messageList.length - 1] : null;
  // console.log(structuredClone(listLastMessage?.content), structuredClone(conversation.lastMessageInfo.content));

  if (listLastMessage && conversation.lastMessageInfo.createdAt === listLastMessage.createdAt) return true;

  return false;
};

export const getAttachmentViewContent = (attachment: MessageAttachment) => {
  if (!attachment) return "";

  if (isImage(attachment.mimetype)) {
    return "Hình ảnh";
  } else if (isVideo(attachment.mimetype)) {
    return "Video";
  } else if (isAudio(attachment.mimetype)) {
    return "Audio";
  } else {
    return attachment.originalname;
  }
};

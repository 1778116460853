import Account from "@domain/entities/account-entity";

import { UpdateConversationDetailsBody } from "@domain/interfaces/conversation-details-interface";
import {
  ConversationAction,
  CreateConversationBody,
  GetConversationMemberQuery,
  GetRecentContactQuery,
  GetUserConversationQuery,
  ImportantConversationResponse,
  UpdateConversationBody,
  UpdatePinConversationBody,
} from "@domain/interfaces/conversation-interface";
import {
  CreateMessageBody,
  EditMessageResponse,
  GetMessageDetailQuery,
  GetMessageListQuery,
  GetUserSeenMessageQuery,
  NewMessageResponse,
  PinMessageBody,
  ReactionMessageBody,
  ReplyBotMessageBody,
  SearchMessageWithinConversationQuery,
  SendReactionResponse,
  UserSeenResponse,
} from "@domain/interfaces/message-interface";
import { UpdateAccountInfoResponse } from "./account-interface";
import { SpeechToTextBody } from "./attachment-interface";
import { UpdateChatWebFeatureResponse } from "./config-interface";
import { ICreateFolderBody, IGetFolderListQuery, IUpdateFolderBody } from "./folder-interface";
import { IGetUserListQuery } from "./user-interface";

export interface ISocketResponse {
  status: number;
  code: number;
  message: string;
  data: any;
}

export class SocketResponse<T> implements ISocketResponse {
  status: number;
  code: number;
  message: string;
  data: any;

  constructor (status: number, code: number, message: string, data: T) {
    this.status = status;
    this.code = code;
    this.message = message;
    this.data = data;
  }
}

export interface ServerToClientEvents {
  error: (Error: { status: number; code: number; message: string }) => void;

  // config
  getConfig: (data: { today: Date }) => void;

  // message
  newMessage: (data: NewMessageResponse) => void;
  sendReaction: (response: SendReactionResponse) => void;
  userSeen: (response: UserSeenResponse) => void;
  editMessage: (response: EditMessageResponse) => void;

  // user
  loginSuccess: (data: { accessToken: string; user: Account }) => void;
  qrCodeExpired: () => void;
  userUpdateInfo: (response: UpdateAccountInfoResponse) => void;

  // config
  updateChatWebFeature: (response: UpdateChatWebFeatureResponse) => void;

  // conversation
  importantConversation: (response: ImportantConversationResponse) => void;
}

export interface ClientToServerEvents {
  // bot
  "bot:replyToBotMessage": (body: ReplyBotMessageBody, callback: (data: ISocketResponse) => void) => void;

  // attachment
  "attachment:speechToText": (body: SpeechToTextBody, callback: (data: ISocketResponse) => void) => void;

  // message

  "message:getList": (query: GetMessageListQuery, callback: (data: ISocketResponse) => void) => void;
  "message:create": (data: CreateMessageBody, callback: (data: ISocketResponse) => void) => void;
  "message:remove": (id: string, callback: (data: ISocketResponse) => void) => void;
  "message:reaction": (data: ReactionMessageBody, callback: (data: ISocketResponse) => void) => void;
  "message:search": (query: { search: string; page: number }, callback: (data: ISocketResponse) => void) => void;
  "message:searchWithinConversation": (query: SearchMessageWithinConversationQuery, callback: (data: ISocketResponse) => void) => void;
  "message:totalUnreadCount": (query: any, callback: (data: ISocketResponse) => void) => void;
  "message:edit": (id: string, body: { content: string }, callback: (data: ISocketResponse) => void) => void;
  "message:pin": (body: PinMessageBody, callback: (data: ISocketResponse) => void) => void;
  "message:removePin": (body: { conversationId: string; messageId: string }, callback: (data: ISocketResponse) => void) => void;
  "message:getPinList": (body: { conversationId: string }, callback: (data: ISocketResponse) => void) => void;
  "message:unpin": (body: { conversationId: string; messageIds: string[] }, callback: (data: ISocketResponse) => void) => void;
  "message:getCount": (
    query: {
      conversationId: string;
      fromMessage: { id: string; createdAt: Date };
      toMessage: { id: string; createdAt: Date };
    },
    callback: (data: ISocketResponse) => void
  ) => void;
  "message:getUserSeen": (query: GetUserSeenMessageQuery, callback: (data: ISocketResponse) => void) => void;
  // conversation
  "conversation:getPinList": (query: any, callback: (data: ISocketResponse) => void) => void;
  "conversation:updatePinConversation": (body: UpdatePinConversationBody, callback: (data: ISocketResponse) => void) => void;

  "conversation:getDetail": (query: GetMessageDetailQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:getList": (query: GetUserConversationQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:getMediaList": (query: GetMessageListQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:getFileList": (query: GetMessageListQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:getLinkList": (query: GetMessageListQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:update": (action: ConversationAction, data: UpdateConversationBody, callback: (data: ISocketResponse) => void) => void;
  "conversation:create": (data: CreateConversationBody, callback: (data: ISocketResponse) => void) => void;
  "conversation:search": (query: { search: string }, callback: (data: ISocketResponse) => void) => void;
  "conversation:getMembers": (query: GetConversationMemberQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:createSaveMessage": (data: any, callback: (data: ISocketResponse) => void) => void;
  "conversation:remove": (data: { id: string }, callback: (data: ISocketResponse) => void) => void;
  "conversation:getRecentContacts": (data: GetRecentContactQuery, callback: (data: ISocketResponse) => void) => void;
  // conversation details
  "conversationDetails:update": (data: UpdateConversationDetailsBody, callback: (data: ISocketResponse) => void) => void;

  // user
  "user:getList": (query: IGetUserListQuery, callback: (data: ISocketResponse) => void) => void;
  "user:search": (query: { search: string }, callback: (data: ISocketResponse) => void) => void;

  // folder
  "folder:getList": (body: IGetFolderListQuery, callback: (data: ISocketResponse) => void) => void;
  "folder:create": (body: ICreateFolderBody, callback: (data: ISocketResponse) => void) => void;
  "folder:update": (body: IUpdateFolderBody, callback: (data: ISocketResponse) => void) => void;
  "folder:remove": (body: { id: string }, callback: (data: ISocketResponse) => void) => void;
}

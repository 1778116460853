/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { UpdateConversationDetailsBody, UpdateConversationDetailsResponse } from "@domain/interfaces/conversation-details-interface";
import {
  ConversationAction,
  CreateConversationBody,
  CreateConversationResponse,
  GetConversationDetailResponse,
  GetConversationLinkResponse,
  GetConversationListResponse,
  GetConversationMediaResponse,
  GetConversationMemberQuery,
  GetConversationMemberResponse,
  GetMediaListQuery,
  GetPinConversationQuery,
  GetRecentContactQuery,
  GetRecentContactsResponse,
  GetSaveMessageConversationResponse,
  GetUserConversationQuery,
  RemoveConversationResponse,
  SearchConversationQuery,
  SearchConversationResponse,
  UpdateConversationBody,
  UpdateConversationResponse,
  UpdatePinConversationBody,
} from "@domain/interfaces/conversation-interface";
import { GetMessageDetailQuery } from "@domain/interfaces/message-interface";

export default abstract class ConversationRepository {
  getDetail (query: GetMessageDetailQuery): Promise<GetConversationDetailResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getList (query: GetUserConversationQuery): Promise<GetConversationListResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getPinList (query: GetPinConversationQuery): Promise<GetConversationListResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getSaveMessageConversation (): Promise<GetSaveMessageConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  create (body: CreateConversationBody): Promise<CreateConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  updateConversationDetails (body: UpdateConversationDetailsBody): Promise<UpdateConversationDetailsResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  updatePinConversation (body: UpdatePinConversationBody): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  updateConversation (action: ConversationAction, body: UpdateConversationBody): Promise<UpdateConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  removeConversation (id: string): Promise<RemoveConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getMediaList (query: GetMediaListQuery): Promise<GetConversationMediaResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getFileList (query: GetMediaListQuery): Promise<GetConversationMediaResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getLinkList (query: GetMediaListQuery): Promise<GetConversationLinkResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  search (query: SearchConversationQuery): Promise<SearchConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getMember (query: GetConversationMemberQuery): Promise<GetConversationMemberResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getRecentContacts (query: GetRecentContactQuery): Promise<GetRecentContactsResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}

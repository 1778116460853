import UserRepository from "@domain/repositories/user-repository";

import { DEFAULT_TIMEOUT } from "@common/constant";
import ErrorCode from "@common/kernel/error-code";
import { SocketResponse } from "@domain/interfaces/socket-interface";
import { GetUserListResponse, IGetUserListQuery } from "@domain/interfaces/user-interface";
import { socket } from "@infrastructure/network/socket";
import { injectable } from "inversify";

@injectable()
export default class UserSocketRepository implements UserRepository {
  getList (query: IGetUserListQuery): Promise<GetUserListResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("user:getList", query, (error, data) => {
        if (error) {
          resolve(
            new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {
              internal: 0,
            })
          );
        }
        resolve(data);
      });
    });
  }
}

import { IFolder } from "@domain/interfaces/folder-interface";

export default class Folder implements IFolder {
  id: string;
  name: string;
  username: string;
  icon: string;
  conversations: string[];
  unreadCount!: number;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;

  constructor (data: Folder) {
    this.id = data.id;
    this.name = data.name;
    this.username = data.username;
    this.icon = data.icon;
    this.conversations = data.conversations;
    this.createdBy = data.createdBy;
    this.updatedBy = data.updatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

import repository from "@config/repository";

import { GetMediaListQuery } from "@domain/interfaces/conversation-interface";
import ConversationRepository from "@domain/repositories/conversation-repository";

export default function getConversationLinkListUseCase (query: GetMediaListQuery) {
  const conversationRepository = repository.get<ConversationRepository>(ConversationRepository);

  return conversationRepository.getLinkList(query);
}

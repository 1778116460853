import StickerRepository from "@domain/repositories/theadvance-media/sticker-repository";
import TheAdvanceMediaService from "@infrastructure/network/theadvance-media";

import { GetStickerListQuery, GetStickerListResponse } from "@domain/interfaces/sticker-interface";
import { injectable } from "inversify";

@injectable()
export default class TheAdvanceMediaStickerRepository implements StickerRepository {
  private api = new TheAdvanceMediaService();

  async getStickerList (query: GetStickerListQuery): Promise<GetStickerListResponse> {
    return await this.api.get({
      path: "/user-sticker-set",
      query,
    });
  }
}

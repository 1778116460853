import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";
import UploadMediaRepository from "@domain/repositories/theadvance-media/media-repository";

import { getBlobFromBase64 } from "@common/file";
import { getContent, getParseMode } from "@domain/helpers/message-helper";
import { CreateMessageBody, ICreateMessageParams, MessageAttachment } from "@domain/interfaces/message-interface";

export default async function createMessageUseCase (data: ICreateMessageParams) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);
  const mediaRepository = repository.get<UploadMediaRepository>(UploadMediaRepository);

  const body: CreateMessageBody = {
    content: getContent(data.content),
    conversationId: data.conversationId,
    attachment: [],
    requestId: data.requestId,
    action: data.action,
    rootMessage: data.rootMessage,
    mention: data.mention,
    quote: data.quote,
    parseMode: getParseMode(data.content),
    sticker: data.sticker,
  };

  if (data.files) {
    for (let index = 0; index < data.files.length; index++) {
      const file = data.files[index];
      const result = await mediaRepository.uploadMedia(file);

      if (result.data.id && result.data.link) {
        body.attachment.push({
          id: result.data.id,
          link: result.data.link,
          mimetype: result.data.mimetype,
          size: result.data.size,
          originalname: file.name,
          thumbnail: result.data.thumbnail,
          duration: 0,
          height: result.data.height,
          width: result.data.width,
          isRecord: false,
        } as MessageAttachment);
      }
    }
  }

  if (data.record && data.record.blob) {
    let blob = data.record.blob;

    if (!(blob instanceof Blob) && data.record.base64) {
      blob = getBlobFromBase64(data.record.base64);
    }

    const result = await mediaRepository.uploadRecord(blob, data.record.duration);

    if (result.data.link && result.data.id) {
      body.attachment.push({
        id: result.data.id,
        link: result.data.link,
        mimetype: result.data.mimetype,
        size: result.data.size,
        originalname: result.data.filename,
        thumbnail: result.data.thumbnail,
        duration: result.data.duration,
        height: result.data.height,
        width: result.data.width,
        waveform: result.data.waveform,
        isRecord: true,
      } as MessageAttachment);
    } else {
      return;
    }
  }
  body.createdAt = new Date();
  const createMessageResult = await messageRepository.create(body);

  if (createMessageResult && createMessageResult.data && createMessageResult.data.message?.id) {
    return createMessageResult;
  } else {
    console.log(createMessageResult);
  }
}

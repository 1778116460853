import { isVersionGreater } from "@common/utils/common";
import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConfigStore } from "@presentation/store/config-store";
import { Socket } from "socket.io-client";
import { handleDebug } from "../helper";

export default function onUpdateChatWebFeature (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("updateChatWebFeature", (result) => {
    handleDebug("updateChatWebFeature", result.data);

    const configStore = useConfigStore();

    if (result.data.feature === "newVersion" && isVersionGreater(result.data.value, appVersion)) {
      configStore.newVersion = true;
    }
  });
}

/* eslint-disable max-len */
export default {
  env: "development",
  host: "https://chatdev.theadvance.com",
  api: {
    host: "https://chatapidev.theadvance.com",
    product: "theAdvanceChatWeb",
    token: "ad4090f0d2fd464b6ec05d0bb88397bc6d8d2427b1c0ffab2ca548e4a68fe2e3",
    key: "THE_ADVANCE_CHAT_2024",
  },
  theAdvanceMedia: {
    host: "https://mediadev.theadvance.com",
    product: "chatWeb",
    token: "572d3c25e95edba64b2558af6743412c806219416c42ca162d7d513828b3e61d",
    maxSizeUpload: 5242880,
  },
  firebaseConfig: {
    apiKey: "AIzaSyC5gRAcFqz_uUgDRSvEuFFY2DJnm6sxz4I",
    authDomain: "the-advance-6720e.firebaseapp.com",
    projectId: "the-advance-6720e",
    storageBucket: "the-advance-6720e.firebasestorage.app",
    messagingSenderId: "209554965208",
    appId: "1:209554965208:web:e78ac0fd8a04a05b3f8dad",
    measurementId: "G-E39N1RDDLD",
  },
};

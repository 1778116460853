import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HorizontalList',
  setup(__props) {

const scrollContainer = ref<HTMLDivElement | null>(null);
let scrollAmount = 0;
let isScrolling = false;

const smoothScroll = () => {
  if (!scrollContainer.value) return;

  scrollContainer.value.scrollLeft += scrollAmount;
  scrollAmount *= 0.92; // Less damping for faster stopping

  if (Math.abs(scrollAmount) > 1) {
    requestAnimationFrame(smoothScroll);
  } else {
    isScrolling = false;
  }
};

const handleWheelScroll = (event: WheelEvent) => {
  event.preventDefault();
  scrollAmount += event.deltaY * 1.5; // Increased speed multiplier

  if (!isScrolling) {
    isScrolling = true;
    requestAnimationFrame(smoothScroll);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex overflow-scroll flex-nowrap",
    ref_key: "scrollContainer",
    ref: scrollContainer,
    onWheel: handleWheelScroll
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 544))
}
}

})
import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useAccountStore } from "@presentation/store/account-store";
import { Socket } from "socket.io-client";
import { handleDebug } from "../helper";

export default function onUpdateUserInfo (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("userUpdateInfo", (result) => {
    handleDebug("userUpdateInfo", result.data);

    if (result.code === 1 && result.data?.user) {
      const accountStore = useAccountStore();
      accountStore.loginUser = result.data.user;
    }
  });
}

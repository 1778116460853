import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import { VueSpinner, VueSpinnerDots, VueSpinnerPuff, VueSpinnerTail } from "vue3-spinners";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLoading',
  props: {
    loading: { type: Boolean },
    containerClass: {},
    size: {},
    type: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.containerClass ? _ctx.containerClass : 'middle')
      }, [
        (_ctx.type === 'puff')
          ? (_openBlock(), _createBlock(_unref(VueSpinnerPuff), {
              key: 0,
              size: _ctx.size,
              color: "var(--color-primary-500)"
            }, null, 8, ["size"]))
          : (_ctx.type === 'dots')
            ? (_openBlock(), _createBlock(_unref(VueSpinnerDots), {
                key: 1,
                size: _ctx.size,
                color: "var(--color-primary-500)"
              }, null, 8, ["size"]))
            : (_ctx.type === 'tail')
              ? (_openBlock(), _createBlock(_unref(VueSpinnerTail), {
                  key: 2,
                  size: _ctx.size,
                  color: "var(--color-primary-500)"
                }, null, 8, ["size"]))
              : (_openBlock(), _createBlock(_unref(VueSpinner), {
                  key: 3,
                  size: _ctx.size,
                  color: "var(--color-primary-500)"
                }, null, 8, ["size"]))
      ], 2))
    : _createCommentVNode("", true)
}
}

})
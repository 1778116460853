import { Metadata } from "@domain/interfaces/conversation-interface";
import axios from "axios";

export default async function getLinkMetadataUseCase (url: string) {
  if (!url) return;
  const result = await axios.get(`${gConfig.api.host}/metadata?url=` + url);

  if (result.status === 200 && result.data) {
    return result.data as Metadata;
  }

  return {} as Metadata;
}

import getStickerListUseCase from "@domain/use-cases/media/get-sticker-list";

import { IStickerSet } from "@domain/interfaces/sticker-interface";
import { defineStore } from "pinia";

export const useStickerStore = defineStore("sticker", {
  state: () => ({
    userSticker: {
      items: [] as IStickerSet[],
      fetching: false,
    },
  }),
  actions: {
    async getStickerList () {
      const result = await getStickerListUseCase({ page: 1 });

      if (result.code !== 1) return;
      this.userSticker.items = result.data.items.filter((item) => item.stickers.length > 0);
    },
  },
});

import "reflect-metadata";

import { Container } from "inversify";

import MessageRepository from "@domain/repositories/message-repository";
import MessageRepositoryApi from "@infrastructure/repositories/api/message-api-repository";

import AccountRepository from "@domain/repositories/account-repository";
import AccountRepositoryApi from "@infrastructure/repositories/api/account-api-repo";

import ConversationRepository from "@domain/repositories/conversation-repository";
import ConversationApiRepository from "@infrastructure/repositories/api/conversation-api-repository";

import UploadMediaRepository from "@domain/repositories/theadvance-media/media-repository";
import TheAdvanceMediaUploadRepository from "@infrastructure/repositories/theadvance-media/media-repository";

import UserRepository from "@domain/repositories/user-repository";
import UserSocketRepository from "@infrastructure/repositories/api/user-socket-repo";

import FolderRepository from "@domain/repositories/folder-repository";
import FolderSocketRepository from "@infrastructure/repositories/api/folder-api-repository";

import AttachmentRepository from "@domain/repositories/attachment-repository";
import StickerRepository from "@domain/repositories/theadvance-media/sticker-repository";
import AttachmentApiRepository from "@infrastructure/repositories/api/attachment-api-repo";
import TheAdvanceMediaStickerRepository from "@infrastructure/repositories/theadvance-media/sticker-repository";

const repository = new Container();

if (process.env.VUE_APP_REPOSITORY === "api") {
  repository.bind<MessageRepository>(MessageRepository).to(MessageRepositoryApi);
  repository.bind<AccountRepository>(AccountRepository).to(AccountRepositoryApi);
  repository.bind<ConversationRepository>(ConversationRepository).to(ConversationApiRepository);
  repository.bind<UploadMediaRepository>(UploadMediaRepository).to(TheAdvanceMediaUploadRepository);
  repository.bind<UserRepository>(UserRepository).to(UserSocketRepository);
  repository.bind<FolderRepository>(FolderRepository).to(FolderSocketRepository);
  repository.bind<AttachmentRepository>(AttachmentRepository).to(AttachmentApiRepository);
  repository.bind<StickerRepository>(StickerRepository).to(TheAdvanceMediaStickerRepository);
}

export default repository;

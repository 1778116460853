import DOMPurify from "dompurify";

/* eslint-disable no-useless-escape */
export const handleCopy = (text?: string) => {
  if (!text) return;

  navigator.clipboard.writeText(text);
};

export function isLink (text: string) {
  if (!text) return false;
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  return !!text.match(urlRegex);
}

export function stringToSlug (str: string) {
  // remove accents
  const from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ";
  const to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\-]/g, "-")
    .replace(/-+/g, "-");

  return str;
}

export function removeHtmlTags (text: string) {
  if (!text) return "";

  return text.replace(/<[^>]*>?/gm, "");
}

export function findText (text: string) {
  if (!text) return false;

  return text.match("[A-Z]([a-z]+|.)(?:s+[A-Z]([a-z]+|.))*(?:s+[a-z][a-z-]+){0,2}s+[A-Z]([a-z]+|.");
}

export const cleanHtml = (html: string) => {
  const allowedTags = ["b", "strong", "code", "i", "s", "u", "br", "a"];

  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: allowedTags, // Chỉ cho phép các thẻ hợp lệ
    ALLOWED_ATTR: [], // Loại bỏ tất cả các thuộc tính
    USE_PROFILES: { html: true },
  });
};

export function stringToDom (htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(cleanHtml(htmlString), "text/html");

  return doc.body.childNodes;
}

export function getFirstLineWithoutBr (html: string): string {
  // Tạo một DOM element từ chuỗi HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Lặp qua các child nodes để tìm dòng đầu tiên không phải là thẻ <br>
  for (const child of tempDiv.childNodes) {
    if (child.nodeType === Node.TEXT_NODE) {
      // Nếu là Text Node, kiểm tra nội dung không phải là khoảng trắng
      const textContent = child.textContent?.trim();

      if (textContent) {
        return textContent;
      }
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      const element = child as HTMLElement;

      // Bỏ qua nếu là <br>, lấy nội dung khác
      if (element.tagName.toLowerCase() !== "br") {
        return element.outerHTML;
      }
    }
  }

  return ""; // Trả về chuỗi rỗng nếu không tìm thấy dòng phù hợp
}

export function isVersionGreater (v1: string, v2: string): boolean {
  const parts1 = v1.split(".").map(Number);
  const parts2 = v2.split(".").map(Number);

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const num1 = parts1[i] || 0; // Nếu thiếu phần tử, mặc định là 0
    const num2 = parts2[i] || 0;

    if (num1 > num2) return true;

    if (num1 < num2) return false;
  }

  return false; // Nếu bằng nhau hoặc nhỏ hơn thì trả về false
}

export function removeExistingIds (arr1: any, arr2: any) {
  const idsToRemove = new Set(arr2.map((obj: any) => obj.id));

  return arr1.filter((obj: any) => !idsToRemove.has(obj.id));
}

export function isValidURL (url: string): boolean {
  // eslint-disable-next-line prefer-regex-literals
  const regex = new RegExp(/^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:\d+)?(\/.*)?$/);

  return regex.test(url);
}

export function getHostFromUrl (url: string) {
  try {
    return new URL(url).host;
  } catch (error) {
    return url;
  }
}

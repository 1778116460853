import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { getBase64 } from "@common/file";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppAvatar',
  props: {
    src: {},
    size: {},
    icon: {},
    upload: { type: Boolean },
    uploadFile: {}
  },
  emits: ["update:src", "update:uploadFile"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;
const avatarRef = ref();
const onChangeFile = async (event: any) => {
  const src = await getBase64(event.target?.files[0]);
  emit("update:uploadFile", event.target?.files[0]);
  emit("update:src", src);
};

return (_ctx: any,_cache: any) => {
  const _component_a_avatar = _resolveComponent("a-avatar")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = () => (_ctx.upload ? avatarRef.value?.click() : null))
  }, [
    (_ctx.src)
      ? (_openBlock(), _createBlock(_component_a_avatar, {
          key: 0,
          src: _ctx.src,
          size: _ctx.size
        }, null, 8, ["src", "size"]))
      : (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_a_avatar, {
            key: 1,
            size: _ctx.size,
            class: "bg-white rounded-full border-1 border-primary-100"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", { src: _ctx.icon }, null, 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["size"]))
        : (_openBlock(), _createBlock(_component_a_avatar, {
            key: 2,
            size: _ctx.size,
            class: "bg-gray-border text-primary"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                style: _normalizeStyle(`font-size: ${_ctx.size ? _ctx.size / 2 : 16}px`)
              }, "A", 4)
            ]),
            _: 1
          }, 8, ["size"])),
    (_ctx.upload)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 3,
          name: "avatar",
          ref_key: "avatarRef",
          ref: avatarRef,
          type: "file",
          onChange: onChangeFile
        }, null, 544)), [
          [_vShow, false]
        ])
      : _createCommentVNode("", true)
  ]))
}
}

})
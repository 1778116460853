import { getBase64 } from "@common/file";
import { IMessage, IRecordInfo, MessageAttachment } from "@domain/interfaces/message-interface";
import { defineStore } from "pinia";

import getBlobDuration from "get-blob-duration";

export const useAudioStateStore = defineStore("audio-state", {
  state: () => ({
    record: {
      visible: false,
      stream: undefined as MediaStream | undefined,
      recorder: undefined as MediaRecorder | undefined,
      currentMillisecond: 0 as number,
      timer: undefined as any,
      recordOwner: "" as string,
      info: {} as IRecordInfo,
      audioChunks: [] as Blob[],
    },
    audio: {
      info: {
        name: "",
        duration: 0,
        src: "",
        createdAt: new Date(),
      },
      status: "pause" as "pause" | "playing",
      percentagePlayed: 0,
      currentTime: 0,
      playbackRate: 1,
      volume: 1,
      player: null as HTMLAudioElement | null,
      currentIndex: -1 as number,
      currentTrackList: [] as {
        player: HTMLAudioElement;
        audio: MessageAttachment;
        message: IMessage;
      }[],
      tempTrackList: [] as {
        player: HTMLAudioElement;
        audio: MessageAttachment;
        message: IMessage;
      }[],
    },
  }),

  getters: {
    isNextTrack: (state) => state.audio.currentIndex >= 0 && state.audio.currentIndex + 1 < state.audio.currentTrackList.length,
    isPreviousTrack: (state) => state.audio.currentIndex > 0,
  },
  actions: {
    async onStartRecord () {
      this.record.info = {};
      this.record.audioChunks = [];
      this.record.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.record.recorder = new MediaRecorder(this.record.stream, { mimeType: "audio/mp4", audioBitsPerSecond: 64000 });

      this.record.recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.record.audioChunks.push(event.data);
        }
      };

      this.record.recorder.start();
      this.record.timer = setInterval(() => {
        this.record.currentMillisecond = this.record.currentMillisecond + 100;
      }, 100);
      this.record.visible = true;
    },
    async getRecordFile () {
      return new Promise((resolve, reject) => {
        if (this.record.recorder) {
          this.record.recorder.onstop = async () => {
            const audioBlob = new Blob(this.record.audioChunks, { type: "audio/mp4" });

            if (audioBlob) {
              resolve({
                blob: audioBlob,
                base64: await getBase64(audioBlob),
                duration: audioBlob ? await getBlobDuration(audioBlob) : 0,
              });
            }
            reject(new Error("Record file error"));
          };
          this.onStopRecording();
        } else {
          reject(new Error("Record file error"));
        }
      }) as Promise<IRecordInfo>;
    },
    async onStopRecording () {
      if (this.record.recorder && this.record.recorder.state !== "inactive") {
        this.record.visible = false;
        this.record.currentMillisecond = 0;

        clearInterval(this.record.timer);
        this.record.timer = undefined;
        this.record.recorder.stop();
        this.record.stream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      }
    },
    async updateCurrentPlayer () {
      //
    },
    playAudio ({
      messageId,
      src,
      name,
      duration,
      createdAt,
      player,
      currentTime,
      playbackRate,
      volume,
    }: {
      messageId: string;
      src: string;
      name: string;
      duration: number;
      createdAt: Date;
      player?: HTMLAudioElement;
      currentTime?: number;
      playbackRate?: number;
      volume?: number;
    }) {
      if (!player) {
        return;
      }

      this.audio.info.src = src;
      this.audio.info.name = name;
      this.audio.info.duration = duration;
      this.audio.info.createdAt = createdAt;
      this.audio.status = "playing";

      if (this.audio.player) {
        if (player !== this.audio.player) {
          this.audio.player.pause();
          this.audio.player.currentTime = 0;
        }
      }

      this.audio.player = player;
      this.audio.player.currentTime = currentTime || 0;
      this.audio.player.playbackRate = playbackRate || 1;
      this.audio.player.volume = volume || 1;
      this.audio.currentIndex = this.audio.currentTrackList.findIndex((track) => track.message.id === messageId);
      this.audio.player.play();
      const audioPlayer = document.getElementById("audioPlayer");
      audioPlayer?.append(this.audio.player);
      this.audio.player.ontimeupdate = () => {
        if (this.audio.info.duration === 0) return;

        if (this.audio.player?.currentTime) {
          this.audio.currentTime = this.audio.player.currentTime;
          this.audio.percentagePlayed = (this.audio.player.currentTime / duration) * 100;
        }
      };
      this.audio.player.onended = () => {
        this.audio.status = "pause";
        this.audio.percentagePlayed = 0;
        this.audio.currentTime = 0;

        if (this.audio.player) {
          this.audio.player.currentTime = this.audio.currentTime;
        }

        if (this.isNextTrack) {
          this.goToNextTrack();
        } else {
          this.stopAudio();
        }
      };
    },
    continuePlayAudio () {
      if (this.audio.player) {
        this.audio.player.play();
        this.audio.status = "playing";
      }
    },
    pauseAudio () {
      this.audio.player?.pause();
      this.audio.status = "pause";
    },
    stopAudio () {
      this.audio.status = "pause";
      this.audio.percentagePlayed = 0;
      this.audio.currentTime = 0;
      this.audio.info.name = "";
      this.audio.info.duration = 0;
      this.audio.info.src = "";
      this.audio.currentTrackList = [];

      if (this.audio.player) {
        this.audio.player.pause();
        this.audio.player.currentTime = 0;
      }
      this.audio.player = null;
    },
    goToPreviousTrack () {
      if (this.isPreviousTrack) {
        const previousTrack = this.audio.currentTrackList[this.audio.currentIndex - 1];
        this.playAudio({
          messageId: previousTrack.message.id,
          src: previousTrack.audio.link,
          name: previousTrack.message.createdByInfo.name,
          duration: previousTrack.audio.duration || 0,
          createdAt: previousTrack.message.createdAt,
          currentTime: 0,
          player: previousTrack.player,
          playbackRate: this.audio.playbackRate,
          volume: this.audio.volume,
        });
      }
    },
    goToNextTrack () {
      if (this.isNextTrack) {
        const nextTrack = this.audio.currentTrackList[this.audio.currentIndex + 1];
        this.playAudio({
          messageId: nextTrack.message.id,
          src: nextTrack.audio.link,
          name: nextTrack.message.createdByInfo.name,
          createdAt: nextTrack.message.createdAt,
          duration: nextTrack.audio.duration || 0,
          player: nextTrack.player,
          currentTime: 0,
          playbackRate: this.audio.playbackRate,
          volume: this.audio.volume,
        });
      }

      return this.isNextTrack;
    },
    setCurrentTrackList () {
      // khi play audio message mới update danh sách track
      this.audio.currentTrackList = this.audio.tempTrackList;
    },
    resetTempTrackList () {
      this.audio.tempTrackList = [];
    },
    addTempTrackList (data: { player: HTMLAudioElement; audio: MessageAttachment; message: IMessage }) {
      // khi load audio thì thêm vào list tạm đợi khi bấm play 1 audio thì set danh sách thành ds tạm

      if (this.audio.tempTrackList.find((track) => track.message.id === data.message.id)) {
        return;
      }

      this.audio.tempTrackList = this.audio.tempTrackList
        .concat([data])
        .sort((a, b) => new Date(a.message.createdAt).getTime() - new Date(b.message.createdAt).getTime());
    },
    removeTrackItem (message: { id?: string; requestId?: string }) {
      if (message.id) {
        if (this.audio.player === this.audio.currentTrackList.find((track) => track.message.id === message.id)?.player) {
          this.stopAudio();
        }

        this.audio.currentTrackList = this.audio.currentTrackList.filter((track) => track.message.id !== message.id);
      }

      // xoa recordTrack theo requestId
      if (message.requestId) {
        this.audio.currentTrackList = this.audio.currentTrackList.filter((track) => track.message.requestId !== message.requestId);
      }
    },
  },
});

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmModal',
  props: {
    visible: { type: Boolean },
    modalWidth: {}
  },
  emits: ["update:visible", "submit"],
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: _ctx.modalWidth || '20%',
    centered: "",
    open: _ctx.visible,
    onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:visible', false))),
    footer: null,
    closable: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["width", "open"]))
}
}

})